import "./features.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../common/theme";
import { Typography } from "@mui/material";

function Features() {
  return (
    <ThemeProvider theme={theme}>
      <div className="privacy-policy-container">
        <Typography variant="h3" className="header">
          Features
        </Typography>
        <br />
        <br />
        <Typography variant="h6" className="textStyle">
          <span style={{ fontWeight: "bold" }}>Curated Meal Plans</span>:
          Discover curated daily, weekly, and monthly meal plans from more than
          100 trusted meal providers that fit your lifestyle and budget.
          <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            Multiple Subscriptions for All Meals
          </span>
          : With efoodia, choose separate subscriptions for all your meals, and
          enjoy affordable and customized options at your convenience.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}> Meal Provider Switching</span>:
          Want to try new meal choices? With efoodia, easily switch between meal
          providers every week or month and broaden your culinary horizon.
          <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            Flexibility and Convenience
          </span>
          : Schedule deliveries to multiple locations, including your home or
          workplace! Pause, transfer, or cancel your subscription easily at any
          time with our user-friendly interface.
        </Typography>
      </div>
    </ThemeProvider>
  );
}

export default Features;
