import { Close } from "@material-ui/icons";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import signuplogo from "../../../assets/images/signuplogo.png";
import "./SignUpDialog.css";
import { SignUpForm } from "./SignUpForm";
import logo from "../../../assets/images/logo.png";
import bow from "../../../assets/images/bow.png";
import { useEffect, useState } from "react";
const useStyles = makeStyles({
  box: {
    height: "200vh",
    width: "200vw",
  },
  coloredbox: {
    backgroundColor: "#eeffe4",
  },
  nopadding: {
    padding: 0,
  },
});
export const SignUpDialog = (props) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(props.open); // Add state for dialog open/close

  const handleClose = () => {
    setOpen(false);
    console.log("cleared");
  };

  const handleSignupComplete = () => {
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog open={props.open} hideBackdrop={true} disableScrollLock>
      <DialogContent className="no-padding">
        <Grid container className="mcontainer">
          <Grid item xs={12} l={6} md={6} className="coloredbox">
            <Avatar variant="square" src={signuplogo} className="image" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1em",
              }}
            >
              <Typography className="first-line">
                Register before 30th of April to
              </Typography>
              <Typography className="second-line">Get 10% OFF </Typography>
              <Typography className="third-line">
                on your first order.
              </Typography>
              <Typography className="first-line">
                Don't miss out on our pre-launch offer! Register now
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} l={6} md={6} className="nocoloredbox">
            <IconButton
              onClick={() => {
                props.close();
                console.log("cleared");
              }}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
            <Grid container>
              {loading ? <LinearProgress className="linear-prog" /> : <></>}
              <Grid
                item
                xs={12}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Avatar variant="squared" src={logo} className="logowtext" />
              </Grid>
              <Typography className="grey-text">Sign Up / Login</Typography>
              <SignUpForm
                setLoading={setLoading}
                loading={loading}
                onSignupComplete={handleSignupComplete}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
