import {
	Box,
	Button,
	Stack,
	Typography,
	Container,
	Grid,
	Paper,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	Snackbar,
	Alert,
	AlertTitle,
} from "@mui/material";
import React, { useState } from "react";
import web from "../../../../assets/images/newyear_1.png";
import DownloadApp from "../../AppIcons/DownloadApp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { Close, MenuBook, Spa } from "@material-ui/icons";
import {
	DeliveryDining,
	MedicationLiquid,
	ScheduleSend,
} from "@mui/icons-material";
import SubscribeNow from "../../Registration/SubscribeNow";

const content = [
	{
		id: 1,
		icon: (
			<TrendingDownIcon
				style={{
					fontSize: "60px",
					color: "#28b17b",
					marginBottom: "10px",
				}}
			/>
		),
		header: "Unlock Your Potential with AED 100 Off and a Free Meal",
		content:
			"Subscribe to efoodia and enjoy a " +
			<span style={{ fontWeight: "bold" }}>AED 100</span> +
			" discount discount on your first month! Also, get a free meal with every subscription.It's the perfect kickstart to a healthier and more energized version of yourself.",
	},
	{
		id: 2,
		icon: (
			<Spa
				style={{
					fontSize: "60px",
					color: "#28b17b",
					marginBottom: "10px",
				}}
			/>
		),
		header: "Nutrition Packed Meals for Every Palate",
		content:
			"Discover a world of healthy, nutrition-packed meals curated just for you. From breakfast to dinner, efoodia offers a diverse range of cuisines to suit your taste and dietary preferences. Choose from multiple meal providers on our platform, ensuring a delightful and satisfying culinary experience.",
	},
	{
		id: 3,
		icon: (
			<ScheduleSend
				style={{
					fontSize: "60px",
					color: "#28b17b",
					marginBottom: "10px",
				}}
			/>
		),
		header: "Convenient Scheduling for Your Lifestyle",
		content:
			"We understand the importance of flexibility in your busy schedule. With efoodia, you have the freedom to schedule your meals at your convenience. No more compromising on your nutrition – enjoy delicious and wholesome meals whenever it suits you best.",
	},
	{
		id: 4,
		icon: (
			<DeliveryDining
				style={{
					fontSize: "60px",
					color: "#28b17b",
					marginBottom: "10px",
				}}
			/>
		),
		header: "Free Home Delivery",
		content:
			"Sit back, relax, and let efoodia take care of the rest. Enjoy hassle-free home delivery, ensuring your nutritious meals are delivered right to your doorstep. Take the stress out of meal planning and focus on what matters most – your fitness journey.",
	},
	// {
	// 	id: 5,
	// 	icon: (
	// 		<MedicationLiquid
	// 			style={{
	// 				fontSize: "60px",
	// 				color: "#28b17b",
	// 				marginBottom: "10px",
	// 			}}
	// 		/>
	// 	),
	// 	header: "Free Nutritionist Consultation",
	// 	content:
	// 		"As a bonus, for selected Gyms we're offering a complimentary nutritionist consultation to help you personalize your meal plan. Our experts are here to guide you on",
	// },
	{
		id: 6,
		icon: (
			<MenuBook
				style={{
					fontSize: "60px",
					color: "#28b17b",
					marginBottom: "10px",
				}}
			/>
		),
		header: "Choose Your Cuisine, Shape Your Wellness",
		content:
			"Dive into a variety of cuisines tailored to your liking. Whether you're a fan of Mediterranean, Continental, Asian, or local delights, efoodia has something for everyone. We believe in providing choices that align with your unique taste buds and nutritional needs",
	},
];

function NewYear(props) {
	const [open, setOpen] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);
	const handleSuccess = () => {
		setShowSuccess(true);
	};
	const handleError = () => {
		setShowError(true);
		setShowSuccess(false);
	};
	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					bgcolor: "#fafafa",
					width: "100%",
					height: { md: "70vh" },
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					flexDirection: { md: "row", xs: "column" },
				}}
			>
				<Grid
					container
					spacing={2}
					direction={"row"}
					sx={{
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "row",
						p: 1,
					}}
				>
					<Grid item md={1} sx={{ display: { md: "flex", xs: "none" } }}></Grid>
					<Grid item md={5} xs={12}>
						<Typography
							variant='h3'
							sx={{
								fontFamily: "Arial",
								m: 1,
								textAlign: { xs: "center", md: "left" },
							}}
						>
							Welcome to a Healthier You in 2024 with{" "}
							<span style={{ color: "#28b17b", fontWeight: "bold" }}>
								efoodia!
							</span>
						</Typography>
						<Typography
							variant='body1'
							sx={{
								fontFamily: "Arial",
								textAlign: { xs: "center", md: "left" },
							}}
						>
							Embark on a journey towards your fitness goals with our exclusive
							New Year offer on dietary meals – now available at selected gym
							outlets in the UAE! 🏋️‍♂️
						</Typography>
						<Box
							sx={{
								flexGrow: 0,
								mr: 1,
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								bgcolor: "#ffffff",
								width: { md: 400, xs: 1 },
								borderRadius: 30,
								borderStyle: "solid",
								borderWidth: 0.5,
								borderColor: "#28b17b",
								mt: 2,
							}}
						>
							<DownloadApp size='small' />
							<div onClick={() => setOpen(true)}>
								<Typography
									variant='body1'
									style={{
										marginLeft: "10px",
										fontSize: 20,
										fontWeight: "bold",
										color: "#28b17b",
									}}
								>
									Know More
								</Typography>
							</div>
						</Box>
					</Grid>
					<Grid item md={6} xs={12}>
						<Box
							sx={{
								alignItems: "center",
								justifyContent: "center",
								display: "flex",
								width: { xs: 1, md: 600 },
							}}
						>
							<img
								src={
									"https://ik.imagekit.io/efoodia/WhatsApp%20Image%202023-12-28%20at%2017.08.40.jpeg?updatedAt=1703773733007"
								}
								width={"100%"}
								height={"100%"}
								style={{ objectFit: "contain" }}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<Container
				style={{
					alignItems: "center",
					justifyContent: "center",

					alignSelf: "center",
					width: "100%",
					flexDirection: "row",

					paddingBottom: "30px",
				}}
			>
				<Grid
					container
					sx={{
						width: "100%",

						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "row",
					}}
					direction={"row"}
				>
					{content.map((c) => {
						return (
							<Grid item md={4} key={c.id} xs={12}>
								<Paper
									style={{
										backgroundColor: "#ffffff",
										padding: 16,
										boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
										marginTop: 16,
										alignItems: "center",
										justifyContent: "flex-start",
										display: "flex",
										flexDirection: "column",
										width: 300,
										height: 310,
									}}
								>
									<IconButton>{c.icon}</IconButton>
									<Typography
										variant='h6'
										style={{
											textAlign: "center",
											fontFamily: "Arial",
											fontWeight: "bold",
											marginBottom: "10px",
										}}
									>
										{c.header}
									</Typography>
									<Typography
										variant='body2'
										style={{ textAlign: "center", marginBottom: "10px" }}
									>
										{c.content}
									</Typography>
								</Paper>
							</Grid>
						);
					})}
				</Grid>
			</Container>
			<Container>
				<Box>
					<img
						src={
							"https://ik.imagekit.io/efoodia/34.jpg?updatedAt=1703773698915"
						}
						width='100%'
						heigth='100%'
						style={{ marginTop: "10px", marginBottom: "10px" }}
						onClick={() => setOpen(true)}
					/>
				</Box>
			</Container>
			{/* <Dialog maxWidth='xs' open={open} onClose={() => setOpen(false)}>
				<DialogTitle>
					<IconButton
						aria-label='close'
						onClick={() => setOpen(false)}
						sx={{
							position: "absolute",
							left: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<SubscribeNow
						img={
							"https://ik.imagekit.io/efoodia/header-img.webp?updatedAt=1703066943776"
						}
						onSuccess={handleSuccess}
						onError={handleError}
					/>
				</DialogContent>
			</Dialog> */}
			<Snackbar
				open={showSuccess}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				autoHideDuration={6000}
				onClose={() => setShowSuccess(false)}
			>
				<Alert severity='success'>
					<AlertTitle>Welcome to Encyclofoodia!</AlertTitle>
					You have successfully Subscribed! Our Scout shall contact you soon!
				</Alert>
			</Snackbar>
			<Snackbar
				open={showError}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				autoHideDuration={6000}
				onClose={() => setShowError(false)}
			>
				<Alert severity='error'>
					<AlertTitle>Ooops!</AlertTitle>
					We encountered an error here. You can directly call us at
					+971-551253771
				</Alert>
			</Snackbar>
		</Box>
	);
}
export default NewYear;
