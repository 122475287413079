import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LocalOffer } from "@material-ui/icons";
const bullets = [
	"Building Subscriptions Plans from existing menu.",
	"Cancellation & Refunds",
	"Tracking of Deliveries",
	"Pause & extension",
	"Payment Collection",
	"Order Reports for next day’s delivery",
	"Analytics & Insights to grow business",
	"Professional Packaging",
	"Delivery (Optional)",
];
const Features = () => {
	return (
		<Container>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12} sm={6}>
					<Box style={styles.hero_image_container}>
						<img
							src='https://ik.imagekit.io/efoodia/Web/transparent_bg_corporate.svg?updatedAt=1711881435378'
							style={styles.hero_image}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} sm={1}></Grid>
				<Grid item xs={12} sm={5} style={{ marginTop: 20 }}>
					<Stack gap={2}>
						<Typography style={styles.hero_heading}>
							Free Platform for Subscription Management
						</Typography>
						<Box
							sx={{
								alignItems: "flex-start",
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							{bullets.map(function (bullet) {
								return (
									<Stack
										direction={"row"}
										style={{
											marginTop: 5,
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
											flexDirection: "row",
										}}
										gap={2}
									>
										<CheckCircleIcon style={styles.check} />
										<Typography style={styles.bullet}>{bullet}</Typography>
									</Stack>
								);
							})}
							<Button style={styles.hero_button}>Register Today</Button>
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
};
export default Features;
const styles = {
	hero_button: {
		height: 50,
		borderRadius: 5,
		width: "100%",
		backgroundColor: "#57B368",
		color: "#ffffff",
		fontFamily: "Poppins",
		fontWeight: "bold",
		marginTop: 30,
	},
	check: {
		fontSize: 18,
		color: "#57B368",
	},
	vertical_line: {
		width: "50%",
		height: "100%",
		backgroundColor: "#57B368",
	},
	container: {
		padding: 20,
		boxShadow:
			"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
	},
	line: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	grid: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	paper_highlighted: {
		width: "100%",

		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		cursor: "pointer",
	},
	paper_normal: {
		width: "100%",
		paddingRight: 1,
		backgroundColor: "transparent",
		alignItems: "flex-start",
		justifyContent: "center",
		display: "flex",
		cursor: "pointer",
	},
	title: {
		fontFamily: "Poppins",
		fontWeight: "600",
		fontSize: "16px",
		color: "#444444",
		paddingTop: 10,
	},
	title1: {
		fontFamily: "Poppins",
		fontWeight: "600",
		fontSize: "26px",
		color: "#444444",
		paddingTop: 10,
		textAlign: "center",
	},
	desc: {
		fontFamily: "Poppins",
		fontWeight: "500",
		fontSize: "14px",
		color: "#000000",
		paddingBottom: 5,
	},
	root: {
		width: "100%",
		paddingRight: 10,
		minHeight: "80vh",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	hero_image_container: {
		width: "100%",
		height: "100%",
	},
	hero_image: {
		width: "100%",
		height: "100%",
		objectFit: "contain",
	},
	hero_heading: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		fontColor: "#444444",
		fontSize: 40,
	},
};
