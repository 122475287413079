import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import AndroidIcon from "@mui/icons-material/Android";
import { Apple } from "@material-ui/icons";
const DownloadApp = (props) => {
	const [variant, setVariant] = useState("black");
	const [iconColor, setIconColor] = useState("white");
	const size = props.size == "small" ? 60 : 60;
	useEffect(() => {
		if (props.variant) {
			setVariant(props.variant);
			setIconColor("black");
		} else {
			setVariant("black");
			setIconColor("white");
		}
	}, []);
	return (
		<Box
			sx={{
				width: size,
				height: size,
				p: 1,
				gap: 1,
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-around",
			}}
		>
			<IconButton
				size={props.size}
				sx={{
					bgcolor: variant,
					color: iconColor,
					":hover": { bgcolor: "black", color: "white" },
				}}
				onClick={() =>
					window.open(
						"https://play.google.com/store/apps/details?id=com.encyclofoodia.shop",
						"_blank"
					)
				}
			>
				<i class='lab la-google-play' style={{ fontSize: 24 }}></i>
			</IconButton>
			<IconButton
				size={props.size}
				sx={{
					bgcolor: variant,
					color: iconColor,
					":hover": { bgcolor: "black", color: "white" },
				}}
				onClick={() =>
					window.open(
						"https://apps.apple.com/in/app/encyclofoodia/id6456944295",
						"_blank"
					)
				}
			>
				<Apple />
			</IconButton>
		</Box>
	);
};
export default DownloadApp;
