import {
	Box,
	Container,
	Fade,
	Grid,
	Grow,
	Stack,
	Typography,
	Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Button,
	CssBaseline,
	Divider,
	Paper,
} from "@material-ui/core";
import Testimonial from "../../common/components/Testimonials/Testimonial";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ValueProposition from "../../common/components/ValuePropositions/Value";
import Budget from "../../assets/images/budget.png";
import Healthy from "../../assets/images/healthy.png";
import CTA_img from "../../assets/images/features/cta_lunch.webp";
import CTA from "../../assets/images/features/CTA.png";
import { Add } from "@material-ui/icons";
import TextAnimator from "../../common/components/TextTransitions/TextAnimator";
import "./Hero.css";
import HeroBanners from "./HeroBanners";
import CTAApp from "../../common/components/CTASection/CTAApp";
import Features from "../../common/components/Features/Features";
import TestimonialV2 from "../../common/components/Testimonials/TestimonialV2";
import OurPlans from "../../common/components/OurPlans/OurPlans";
import executive from "../../assets/images/plans/1.svg";
import health from "../../assets/images/plans/2.svg";
import CalorieCalculator from "../../common/components/CalorieCalculator/Calories";
import consult from "../../assets/images/plans/consult.png";
import Play from "../../assets/images/google_play.png";
import Appstore from "../../assets/images/appstore.png";
import ExecutiveMeals from "../../common/components/ExecutiveMeals/ExecutiveMeals";
import Community from "../../common/components/Community/Community";
import Offers from "../../common/components/Offers/Offers";
const FAQs = [
	{
		id: 1,
		question: "What is Efoodia?",
		answer:
			"Efoodia is a meal aggregation & subscription platform. Just like Zomato or Talabat. But, difference is that, Efoodia platform only deals with Meal Subscriptions – Not one time orders.",
	},
	{
		id: 2,
		question: "Where is Efoodia Kitchen?",
		answer:
			"Efoodia doesnt own kitchens. Instead Efoodia partners with curated, verified licensed kitchens across Dubai and Bangalore(India) to offer affordable monthly executive meals.",
	},
	{
		id: 3,
		question: "What is Efoodia Menu?",
		answer:
			"Efoodia is focusing on South Indian, North Indian, Pakistani, Srilankan, Filipino, Continental, Arabic and Healthy alternatives. We also have premium category of meals suitable for people with health conditions and people super sensitive towards what they eat.",
	},
	{
		id: 4,
		question: "How does the delivery work?",
		answer:
			"You need to choose the menu you like by browsing through the meal plans, choose your start and end date, and pay for your plan. Restaurants gets a report on their deliveries to be done on the next day, and restaurant plans and delivers the meal in time.",
	},
	{
		id: 5,
		question: "I'm unable to choose today/tomorrow as start date",
		answer:
			"Encyclofoodia needs you to submit request at least before 8PM for getting deliveries from tomorrow. You cant choose today’s date and if you need to start deliveries from tomorrow, you need to submit before 8PM.",
	},
	{
		id: 6,
		question: "Can I Pay on Delivery?",
		answer:
			"Yes, we have enabled the vendor to choose pay on delivery. If the vendor has enabled Pay on Delivery option, you will be able to pay with your first delivery or before the subscription ends",
	},
	{
		id: 7,
		question: "What if I dont like the food?",
		answer:
			"EFoodia allows you to switch to another meal provider during the subscription duration. You can simply switch to another meal provider by going to your subscription and click on Switch Provider button",
	},
	{
		id: 8,
		question: "What happens if delivery is missed?",
		answer:
			"Your money is safe with us. Efoodia ensures that either you get refund for the day or the vendor delivers meals for an extra day.",
	},
];
function RotatingTable(props) {
	const [currentIndex, setCurrentIndex] = useState(0);

	return (
		<Box sx={{ width: "100%", bgcolor: "#fafafa" }}>
			<Box sx={{ width: "100%", height: "60vh" }} className='dot'>
				<HeroBanners />
			</Box>
			<Container
				fixed
				style={{ minHeight: "60vh", marginTop: 100, marginBottom: 100 }}
			>
				<CTAApp />
			</Container>
			<Container>
				<Typography
					style={{
						fontFamily: "poppins",
						fontSize: "24px",
						fontWeight: "700",
						color: "#444444",
					}}
				>
					Current Offers
				</Typography>
				<Divider style={{ marginTop: 10, marginBottom: 30 }} />
				<Offers />
			</Container>
			<Container fixed style={{ marginTop: 100, minHeight: "70vh" }}>
				<Typography
					style={{
						fontFamily: "poppins",
						fontSize: "24px",
						fontWeight: "700",
						color: "#444444",
					}}
				>
					PRODUCT FEATURES YOU WOULD LOVE
				</Typography>
				<Divider style={{ marginTop: 10, marginBottom: 30 }} />
				<Features />
			</Container>
			<Container fixed sx={{ minHeight: "50vh", marginBottom: 20 }}>
				<Typography
					style={{
						fontFamily: "poppins",
						fontSize: "24px",
						fontWeight: "700",
						color: "#444444",
					}}
				>
					Our Plans
				</Typography>

				<Divider style={{ marginTop: 10 }} />
				<Grid container spacing={2} sx={{ p: 2, marginBottom: 10 }}>
					<Grid item xs={12} md={6}>
						<OurPlans
							img={executive}
							title={"Executive Meals"}
							description={
								"Great Quality, Budget Friendly Meals for working executives from curated Kitchens"
							}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<OurPlans
							img={health}
							title={"Dietary Meals"}
							description={
								"Highly curated Goal based, low carb dietary meal plans for a healthy lifestyle."
							}
						/>
					</Grid>
				</Grid>
			</Container>

			<Container sx={{ p: 5, minHeight: "30vh" }} className='dot'>
				<Typography
					style={{
						fontFamily: "poppins",
						fontSize: "24px",
						fontWeight: "700",
						color: "#444444",
					}}
				>
					Use our Free Calorie Requirement Calculator
				</Typography>

				<Divider style={{ marginTop: 10, marginBottom: 30 }} />
				<CalorieCalculator />
			</Container>
			<Container
				fixed
				sx={{
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					marginBottom: 20,
				}}
			>
				<Button
					style={{
						color: "#ffffff",
						backgroundColor: "#000000",
						alignSelf: "center",
					}}
					onClick={() =>
						window.open(
							"https://apps.apple.com/in/app/encyclofoodia/id6456944295",
							"_blank"
						)
					}
				>
					Get upto 50% off on Low Carb Meals on EFoodia
				</Button>
			</Container>
			<Container
				fixed
				sx={{
					minHeight: "70vh",
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<ExecutiveMeals />
				<Stack gap={1} direction={"row"} alignItems={"center"}>
					<div
						style={{ width: 130, height: 80, cursor: "pointer" }}
						onClick={() =>
							window.open(
								"https://play.google.com/store/apps/details?id=com.encyclofoodia.shop",
								"_blank"
							)
						}
					>
						<img src={Play} width={"100%"} />
					</div>
					<div
						style={{ width: 115, height: 80, cursor: "pointer" }}
						onClick={() =>
							window.open(
								"https://apps.apple.com/in/app/encyclofoodia/id6456944295",
								"_blank"
							)
						}
					>
						<img src={Appstore} width={"100%"} />
					</div>
				</Stack>
			</Container>
			{/* <Container fixed>
				<Paper
					style={{ width: "100%", padding: 1, backgroundColor: "transparent" }}
					alignItems='center'
					elevation={0}
				>
					<Stack alignItems='center'>
						<img src={consult} width={"400"} />
						<Button
							style={{
								color: "#ffffff",
								textTransform: "capitalize",
								backgroundColor: "#53B022",
								marginTop: "20px",
							}}
						>
							Get Free Nutritionist Consultation
						</Button>
					</Stack>
				</Paper>
			</Container> */}

			<Container
				fixed
				sx={{
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					minHeight: "50vh",
					marginTop: 10,
				}}
			>
				<TestimonialV2 />
			</Container>
			<Container
				fixed
				sx={{ minHeight: "40vh", marginBottom: 20, marginTop: 10 }}
			>
				<Community />
			</Container>
			<Container
				style={{
					alignItems: "center",
					justifyContent: "center",
					marginTop: 50,
					display: "flex",
					flexDirection: "row",
					height: "10vh",
				}}
			>
				<Typography
					variant='h3'
					style={{
						fontWeight: "bold",
						fontFamily: "poppins",
						textAlign: "center",
					}}
				>
					Frequently Asked{" "}
					<span style={{ color: "#28b17b", fontFamily: "poppins" }}>
						Questions
					</span>
				</Typography>
			</Container>

			<Box sx={{ display: { xs: "none", md: "flex", mt: 5 } }}>
				<Box
					sx={{
						bgcolor: "#fafafa",
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
						mt: 2,
						gap: 1,
						padding: 1,
					}}
				>
					{FAQs.map((faq) => {
						return (
							<Accordion elevation={0} style={{ width: 700 }}>
								<AccordionSummary
									id='panel1-header'
									expandIcon={<Add />}
									style={{ width: 660 }}
								>
									<Typography
										style={{
											fontSize: 18,
											fontWeight: "500",
											lineHeight: 3,
											fontFamily: "poppins",
										}}
									>
										{faq.question}
									</Typography>
								</AccordionSummary>
								<AccordionDetails
									style={{ fontSize: 16, fontFamily: "poppins" }}
								>
									{faq.answer}
								</AccordionDetails>
							</Accordion>
						);
					})}
				</Box>
			</Box>
			<Box sx={{ display: { xs: "flex", md: "none" } }}>
				<Box
					sx={{
						bgcolor: "#fafafa",
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
						mt: 5,
						gap: 1,
						mb: 1,
					}}
				>
					{FAQs.map((faq) => {
						return (
							<Container style={{ marginTop: 5 }}>
								<Accordion elevation={0} style={{ width: { xs: 1 } }}>
									<AccordionSummary
										id='panel1-header'
										expandIcon={<Add />}
										style={{ width: { xs: 1 } }}
									>
										<Typography
											style={{
												fontSize: 18,
												fontWeight: "bold",
												lineHeight: 3,
												fontFamily: "poppins",
											}}
										>
											{faq.question}
										</Typography>
									</AccordionSummary>
									<AccordionDetails
										style={{ fontSize: 14, fontFamily: "poppins" }}
									>
										{faq.answer}
									</AccordionDetails>
								</Accordion>
							</Container>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
}
function Home2(props) {
	return (
		<div>
			<RotatingTable />
		</div>
	);
}
export default Home2;
