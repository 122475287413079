import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Budget from "../../../assets/images/plans/ExecutiveMeal.svg";
import Healthy from "../../../assets/images/healthy.png";
import { Button, CardActionArea, Grid, Stack } from "@mui/material";
import { ArrowForward } from "@material-ui/icons";

const styles = {
	title: {
		fontFamily: "Poppins",
		fontSize: "24px",
		fontWeight: "bold",
		color: "#444444",
	},
	description: {
		fontFamily: "Poppins",
		fontSize: "18px",
		fontWeight: "500",
		color: "#444444",
	},
};

export default function OurPlans(props) {
	const handleClick = () => {
		props.onClick();
	};

	return (
		<Card sx={{ minHeight: 400 }} onClick={handleClick}>
			<CardActionArea>
				<CardMedia
					component='img'
					height='220'
					image={props.img}
					alt='green iguana'
				/>
				<CardContent>
					<Typography
						gutterBottom
						variant='h5'
						component='div'
						style={styles.title}
					>
						{props.title}
					</Typography>
					<Typography
						variant='body2'
						color='text.secondary'
						style={styles.description}
					>
						{props.description}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Button
					size='small'
					color='primary'
					style={{ backgroundColor: "#53B022", color: "#ffffff", padding: 5 }}
					endIcon={<ArrowForward />}
					onClick={() =>
						window.open(
							"https://apps.apple.com/in/app/encyclofoodia/id6456944295",
							"_blank"
						)
					}
				>
					Get on Efoodia
				</Button>
			</CardActions>
		</Card>
	);
}
