import AboutUs from "../AboutUs/AboutUs";
import CancellationAndRefundPolicy from "../CancellationAndRefundPolicy/CancellationAndRefundPolicy";
import ContactUs from "../ContactUs/ContactUs";
import Features from "../Features/features";
import Blogs from "../common/components/Blogs/Blogs";
import Foodie from "../common/components/Foodie/Foodie";
import Partner from "../common/components/Partner/Partner";
import Restaurant from "../common/components/Restaurant/Restaurant";
import Home from "../home/Home";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import Links from "../Links/Links";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import Store from "../Store/Store";
import Customer from "../common/components/Customer/Customer";
import HomeV2 from "../home/v2/Home";
import NewYear from "../common/components/Pages/Offers/Newyear";
import Home2 from "../home/v2/Home2";
import DeleteUserData from "../Screens/DeleteUserData";
import OffersPage from "../Screens/OffersPage";
import RegistrationSuccess from "../Screens/RegistrationSucess";
import BikerGame from "../Screens/Marketing/BikerGame";
import CorporateLandingPage from "../Screens/Corporate/views/corporate_landing_page";
import ChefHome from "../Screens/Chef/views/ChefHome";

const indexRoutes = [
	{ path: "/", name: "Home", component: <Home2 /> },
	{ path: "/foodie", name: "Foodie", component: <Foodie /> },
	{ path: "/chef", name: "Chef", component: <ChefHome /> },
	{ path: "/restaurants", name: "Restaurants", component: <Restaurant /> },
	{ path: "/partners", name: "Partners", component: <Partner /> },
	{ path: "/blogs", name: "Blogs", component: <Blogs /> },
	{ path: "/new-year", name: "NewYear", component: <NewYear /> },
	{ path: "/deleteme", name: "DeleteUserData", component: <DeleteUserData /> },
	{ path: "/offers", name: "DeleteUserData", component: <OffersPage /> },
	{ path: "/game", name: "BikerGame", component: <BikerGame /> },
	{
		path: "/corporate",
		name: "Corporate",
		component: <CorporateLandingPage />,
	},

	{
		path: "/success",
		name: "RegistrationSuccess",
		component: <RegistrationSuccess />,
	},

	{
		path: "/privacy-policy",
		name: "Privacy Policy",
		component: <PrivacyPolicy />,
	},
	{
		path: "/cancellation-and-refund",
		name: "Cancellation and Refund",
		component: <CancellationAndRefundPolicy />,
	},
	{ path: "/about", name: "About Us", component: <AboutUs /> },
	{ path: "/contact", name: "Contact Us", component: <ContactUs /> },
	{ path: "/Features", name: "Features", component: <Features /> },
	{
		path: "/terms-and-conditions",
		name: "Terms And Conditions",
		component: <TermsAndConditions />,
	},
	{ path: "/links", name: "Links", component: <Links /> },
	{ path: "/goToStore/:id", name: "Store", component: <Store /> },
	{ path: "/customer", name: "Customer", component: <Customer /> },
];
export default indexRoutes;
