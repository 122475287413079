import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import React from "react";

const styles = {
	title: {
		fontFamily: "Poppins",
		fontSize: "28px",
		fontWeight: "bold",
		color: "#37BBF6",
		marginTop: 10,
		textAlign: "center",
	},
	icon: {
		fontSize: 34,
	},
	warning: {
		fontFamily: "Poppins",
		fontSize: "12px",
		fontWeight: "400",
		color: "#444444",
		marginTop: 5,
	},
	warningIcon: {
		fontSize: 20,
		color: "#FA7004",
	},
	stepTitle: {
		textAlign: "center",
		fontFamily: "Poppins",
		fontSize: "28px",
		fontWeight: "bold",
		color: "#444444",
		marginTop: 15,
	},
};
const DeleteUserData = (props) => {
	return (
		<Box style={{ width: "100%", backgroundColor: "#fafafa" }}>
			<Container
				fixed
				sx={{
					minHeight: "80vh",
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
				}}
				className='dot'
			>
				<Stack>
					<Typography style={styles.title}>
						We are Sorry to see you going!
						<span>
							<i class='lar la-sad-cry' style={styles.icon}></i>
						</span>
					</Typography>

					<Box style={{ width: "100%", height: 10 }}>
						<Divider />
					</Box>
					<Typography style={styles.stepTitle}>
						To Delete Your Data Follow steps:
					</Typography>
					<Typography style={styles.warning}>
						Step 1: Login to Efoodia App
					</Typography>
					<Typography style={styles.warning}>
						Step 2: Goto Your Profile
					</Typography>
					<Typography style={styles.warning}>
						Step 3: Click on Delete My Account
					</Typography>
					<Typography style={styles.warning}>
						Step 4: Read the warning, click on Confirm
					</Typography>
					<Box style={{ width: "100%", height: 10, marginTop: 30 }}>
						<Divider />
					</Box>
					<Typography style={styles.title}>Please Note:</Typography>
					<Typography style={styles.warning}>
						<span>
							<i
								class='las la-exclamation-circle'
								style={styles.warningIcon}
							></i>{" "}
						</span>
						All Your existing Subscriptions will be deleted forever.
					</Typography>
					<Typography style={styles.warning}>
						<span>
							<i
								class='las la-exclamation-circle'
								style={styles.warningIcon}
							></i>{" "}
						</span>
						Any pending meals, or refunds will be lost.
					</Typography>
					<Typography style={styles.warning}>
						<span>
							<i
								class='las la-exclamation-circle'
								style={styles.warningIcon}
							></i>{" "}
						</span>
						All user data, including your name, phone, address data will be
						removed.
					</Typography>
					<Typography style={styles.warning}>
						<span>
							<i
								class='las la-exclamation-circle'
								style={styles.warningIcon}
							></i>{" "}
						</span>
						You will no longer be able to retrieve any history including your
						purchase history.
					</Typography>
				</Stack>
			</Container>
		</Box>
	);
};
export default DeleteUserData;
