import { Box, Container, Grid, Paper, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import ProductFeatures from "./ProductFeatures";

const products = [
	{
		id: 1,
		name: "Corporate Digital Food Court",
		desc: "Enable Employees to choose their meal boxes from 80+ Kitchens at Corporate Rates",
		details: [
			{
				parent: "Corporate Digital Food Court",
				img: "https://ik.imagekit.io/efoodia/Web/register.svg?updatedAt=1711944650405",
				title: "Register Employee",
				bullets: [
					"On board the corporate to the corporate scheme.",
					"This generates a unique Coupon code for employee to avail corporate discount.",
				],
				bg: "#f7fdf3",
			},
			{
				parent: "Corporate Digital Food Court",
				img: "https://ik.imagekit.io/efoodia/Web/select.svg?updatedAt=1711944652114",
				title: "Selects Plans",
				bullets: [
					"Employees can choose the meal plan that suits their budget and lifestyle from 100+ meal plans.",
					"Use corporate coupon to avail discounts.",
					"Corporate Dashboard.",
				],
				bg: "#ebf9e5",
			},
			{
				parent: "Corporate Digital Food Court",
				img: "https://ik.imagekit.io/efoodia/Web/deliver.svg?updatedAt=1711944650942",
				title: "Delivered at the Office",
				bullets: [
					"The Subscribed meal is delivered to the employee at the office premises.",
					"The employee can also add other meals of the day and get it delivered to different addresses.",
				],
				bg: "#dcf4ce",
			},
		],
	},
	{
		id: 2,
		name: "Employee Food Allowance Portal",
		desc: "Onboard participants, track their consumption, and integrate with your payroll.",
		details: [
			{
				parent: "Employee Food Allowance Portal",
				img: "https://ik.imagekit.io/efoodia/Web/register.svg?updatedAt=1711944650405",
				title: "Register Employee",
				bullets: [
					"Onboard interested Employees",
					"Allocate wallet balance to the employees and groups.",
					"This generates a unique Coupon code for employee to avail corporate discount.",
				],
				bg: "#f7fdf3",
			},
			{
				parent: "Employee Food Allowance Portal",
				img: "https://ik.imagekit.io/efoodia/Web/select.svg?updatedAt=1711944652114",
				title: "Selects Plans",
				bullets: [
					"Employees choose from 100+ meal plans.",
					"They can use their corporate Wallet to purchase.",
					"The corporate dashboard reflects their subscription data, the discount applied etc.",
				],
				bg: "#ebf9e5",
			},
			{
				parent: "Employee Food Allowance Portal",
				img: "https://ik.imagekit.io/efoodia/Web/deliver.svg?updatedAt=1711944650942",
				title: "Delivered at the Office",

				bullets: [
					"The Subscribed meal is delivered to the employee at the office premises.",
					"The employee can also add other meals of the day and get it delivered to different addresses.",
				],
				bg: "#dcf4ce",
			},
		],
	},
	{
		id: 3,
		name: "Digital Catering Experience",
		desc: "Prevent Cash Leakage, prevent Food Wastage, and get 360 degree employee feedback.",
		details: [
			{
				parent: "Digital Catering Experience",
				img: "https://ik.imagekit.io/efoodia/Web/register.svg?updatedAt=1711944650405",
				title: "Register Employee",
				bullets: [
					"Onboard Employees & Download Efoodia App",
					"Employees schedule and monitor their meals provided by the caterer.",
					"Enable 3 way communication between employee, employer and caterer.",
				],
				bg: "#f7fdf3",
			},
			{
				parent: "Digital Catering Experience",
				img: "https://ik.imagekit.io/efoodia/Web/pay.svg?updatedAt=1711944652095",
				title: "Monitor Employee Meals",
				bullets: [
					"Avoid Food Wastage.",
					"Caterer can concentrate on giving better food experience by investing the money in food quality.",
					"Get 360 degree Feedback",
				],
				bg: "#ebf9e5",
			},
			{
				parent: "Digital Catering Experience",
				img: "https://ik.imagekit.io/efoodia/Web/analytics.svg?updatedAt=1711944649689",
				title: "Delivered at the Office",

				bullets: [
					"Corporate and the caterer Analytics data points to avoid food wastage.",
					"Monitoring and Vendor Payments preventing leakage.",
					"AI driven analytics.",
				],
				bg: "#dcf4ce",
			},
		],
	},
];
const Products = (props) => {
	const [highlighed, setHeighlighed] = useState(1);
	return (
		<Box style={styles.root}>
			<Container>
				<Typography style={styles.hero_heading}>Solutions</Typography>
				<Grid container spacing={2} style={styles.grid}>
					<Grid item xs={12} sm={4}>
						<Stack gap={5}>
							{products.map((product) => {
								return (
									<Paper
										style={
											product.id == highlighed
												? styles.paper_highlighted
												: styles.paper_normal
										}
										onMouseEnter={() => setHeighlighed(product.id)}
										onClick={() => setHeighlighed(product.id)}
										elevation={product.id == highlighed ? 4 : 0}
									>
										<Grid container direction='row'>
											<Grid item xs={1} sm={1}>
												{product.id == highlighed && (
													<Box style={styles.vertical_line}></Box>
												)}
											</Grid>
											<Grid item xs={10} sm={10}>
												<Stack>
													<Typography style={styles.title}>
														{product.name}
													</Typography>
													<Typography style={styles.desc}>
														{product.desc}
													</Typography>
												</Stack>
											</Grid>
										</Grid>
									</Paper>
								);
							})}
						</Stack>
					</Grid>

					<Grid item xs={12} sm={8}>
						<Grid container direction='row' spacing={0}>
							<Grid
								item
								xs={12}
								style={{
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "row",
									display: "flex",
									marginTop: 10,
									marginBottom: 10,
								}}
							>
								<Typography style={styles.title}>
									{products[highlighed - 1].name}
								</Typography>
							</Grid>

							{products[highlighed - 1].details.map((feature) => {
								return (
									<Grid item xs={12} sm={4}>
										<ProductFeatures
											name={feature.parent}
											img={feature.img}
											bullets={feature.bullets}
											title={feature.title}
											bg={feature.bg}
										/>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};
export default Products;
const styles = {
	check: {
		fontSize: 18,
		color: "#57B368",
	},
	vertical_line: {
		width: "50%",
		height: "100%",
		backgroundColor: "#57B368",
	},
	container: {
		boxShadow:
			"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
	},
	line: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	grid: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	paper_highlighted: {
		width: "100%",
		paddingRight: 1,

		alignItems: "flex-start",
		justifyContent: "center",
		display: "flex",
		cursor: "pointer",
	},
	paper_normal: {
		width: "100%",
		paddingRight: 1,
		backgroundColor: "transparent",
		alignItems: "flex-start",
		justifyContent: "center",
		display: "flex",
		cursor: "pointer",
	},
	title: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		fontSize: "16px",
		color: "#444444",
		paddingTop: 10,
	},
	desc: {
		fontFamily: "Poppins",
		fontWeight: "500",
		fontSize: "14px",
		color: "#000000",
		paddingBottom: 5,
	},
	root: {
		width: "100%",
		paddingRight: 10,
		minHeight: "80vh",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	hero_image_container: {
		width: "800px",
		height: "800px",
	},
	hero_small: {
		width: "100%",
		height: "100%",
	},
	hero_image: {
		width: "100%",
		height: "100%",
		objectFit: "contain",
	},
	hero_heading: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		fontColor: "#444444",
		fontSize: 40,
	},
};
