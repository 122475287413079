import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import indexRoutes from "./routes";
import { history } from "./common/helpers/history";
import Header from "./common/components/Header/Header";
import { Footer } from "./common/components/Footer/Footer";
import Store from "./Store/Store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { store } from "./common/reducers/store";
import { ThemeProvider } from "@mui/material";
import theme from "./common/theme";
import HeaderV2 from "./common/components/Header/v2/Header";
import FooterV2 from "./common/components/Footer/v2/Footer";

localStorage.setItem("PopupShown", "false");
let persistor = persistStore(store);

ReactDOM.render(
	/* <App/> */

	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider theme={theme}>
				<BrowserRouter history={history}>
					<HeaderV2 />
					<Routes>
						{indexRoutes.map((prop, key) => {
							return (
								<Route path={prop.path} key={key} element={prop.component} />
							);
						})}
					</Routes>
					<FooterV2 />
				</BrowserRouter>
			</ThemeProvider>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
