import {
	Button,
	Container,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import Sales from "../../Corporate/entities/sales";

const Register = (props) => {
	const [name, setName] = useState("");
	const [license, setLicense] = useState("");
	const [phone, setPhone] = useState("");
	const [kitchenName, setKitchenName] = useState("");
	const [sales, setSales] = useState(new Sales());
	const [error, setError] = useState(false);
	const [submitted, setSubmitted] = useState("");
	const [sumissionError, setSubmissionError] = useState(false);
	const handleSubmit = () => {
		if (!name || !kitchenName || !phone) {
			return setError(true);
		} else {
			setError(false);
		}
		const payload = {
			data: {
				name: name,
				regionId: "India",
				applicationId: "EFCorp",
				pincode: "123456",
				organisationName: kitchenName,
				domain: "unknown",
				phoneNumber: { countryCode: "+91", number: phone },
				email: "unknown@example.com",
				source: "ef_website",
				notes: license,
			},
		};
		sales
			.create_lead(payload)
			.then((res) => {
				setSubmitted(
					"Your request is submitted. Our Account manager shall connect with you shortly."
				);
			})
			.catch((err) => {
				setSubmissionError(true);
			});
	};
	return (
		<Paper style={styles.paper}>
			<Stack gap={2}>
				<Typography style={styles.heading}>
					Get orders from our Corporate Clients
				</Typography>
				<TextField
					variant='filled'
					label='Full Name'
					required
					placeholder='ex. John Doe'
					value={name}
					onChange={(e) => setName(e.target.value)}
					error={!name && error}
					helperText={!name && error ? "This field is required" : ""}
				/>
				<TextField
					variant='filled'
					required
					label='Kitchen Name'
					placeholder="ex. Maria's Kitchen"
					value={kitchenName}
					onChange={(e) => setKitchenName(e.target.value)}
					error={!kitchenName && error}
					helperText={!kitchenName && error ? "This field is required" : ""}
				/>

				<TextField
					variant='filled'
					required
					label='Phone Number'
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					error={!phone && error}
					helperText={!phone && error ? "This field is required" : ""}
				/>
				<TextField
					variant='filled'
					label='FSSAI/License Number'
					value={license}
					placeholder='Your License Number'
					onChange={(e) => setLicense(e.target.value)}
				/>
				{sumissionError && (
					<Typography style={{ color: "red", fontSize: 14, fontWeight: "500" }}>
						Error While Subitting your request
					</Typography>
				)}
				{submitted && (
					<Typography
						style={{ color: "green", fontSize: 14, fontWeight: "500" }}
					>
						{submitted}
					</Typography>
				)}
				<Button style={styles.hero_button} onClick={handleSubmit}>
					Register as Chef
				</Button>
			</Stack>
		</Paper>
	);
};
export default Register;
const styles = {
	hero_button: {
		height: 50,
		borderRadius: 5,
		width: "100%",
		backgroundColor: "#000000",
		color: "#ffffff",
		fontFamily: "Poppins",
		fontWeight: "bold",
	},
	paper: {
		marginTop: 10,
		padding: 10,
	},
	heading: {
		fontFamily: "Poppins",
		fontWeight: "600",
		fontSize: "26px",
		color: "#444444",
		paddingTop: 10,
		textAlign: "center",
	},
};
