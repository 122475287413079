import {
	Avatar,
	Box,
	Button,
	Container,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import contacts from "../../../api/contacts";

function SubscribeNow(props) {
	const [fullname, setFullName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [phoneError, setPhoneError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState("");
	const validateEmail = (mail) => {
		return String(mail)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const validateFullName = (n) => {
		return String(n)
			.toLowerCase()
			.match(/^[a-zA-Z ]+$/);
	};
	function validatePhoneNumber(input_str) {
		var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

		return re.test(input_str);
	}
	const handleEmail = (e) => {
		const val = e.target.value;
		setEmail(val);
		if (validateEmail(val)) {
			setEmailError(false);
		} else {
			setEmailError(true);
		}
	};

	const handlePhone = (e) => {
		const val = e.target.value;
		setPhone(val);
		if (validatePhoneNumber(val)) {
			setPhoneError(false);
		} else {
			setPhoneError(true);
		}
	};
	const handleName = (e) => {
		const val = e.target.value;
		setFullName(val);
		if (validateFullName(val)) {
			setNameError(false);
		} else {
			setNameError(true);
		}
	};

	const handleSubmit = () => {
		let flag = false;
		if (!phone) {
			setPhoneError(true);
			flag = true;
		}
		if (!fullname) {
			setNameError(true);
			flag = true;
		}
		if (!email) {
			setEmailError(true);
			flag = true;
		}
		if (flag) return;

		const payload = {
			properties: {
				email: email,
				firstname: fullname.split(" ")[0],
				lastname: fullname.split(" ")[1],
				phone: phone,
				lifecyclestage: "marketingqualifiedlead",
			},
		};
		contacts
			.subscribe(payload)
			.then((res) => {
				setSuccess(true);
				props.onSuccess();
			})
			.catch((err) => {
				setSuccess(false);
				props.onError(err);
			});
	};
	return (
		<Stack
			sx={{
				gap: 2,
				alignItems: "center",
				justifyContent: "center",
				display: "flex",
				width: { xs: 1, md: 320 },
			}}
		>
			<Avatar src={props.img} sx={{ width: 100, height: 100 }} />
			<Typography
				variant='h5'
				style={{ fontWeight: "bold", fontFamily: "Arial", textAlign: "center" }}
			>
				Register Now to get AED 100 off
			</Typography>
			<TextField
				variant='standard'
				placeholder='Full Name'
				title='Full Name'
				fullWidth
				required
				onChange={(e) => handleName(e)}
				error={nameError}
			/>
			<TextField
				variant='standard'
				placeholder='Phone Number'
				title='Mobile'
				fullWidth
				required
				onChange={(e) => handlePhone(e)}
				error={phoneError}
			/>
			<TextField
				variant='standard'
				placeholder='Email'
				title='Email'
				fullWidth
				required
				onChange={(e) => handleEmail(e)}
				error={emailError}
			/>
			<Button
				fullWidth
				disabled={nameError || phoneError || emailError}
				style={{
					backgroundColor: "#28b17b",
					color: "white",
					".onhover": { backgroundColor: "#28b17b", color: "white" },
				}}
				onClick={handleSubmit}
			>
				Submit
			</Button>
		</Stack>
	);
}
export default SubscribeNow;
