import {
	Avatar,
	Box,
	Button,
	Chip,
	Container,
	Grid,
	Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LocalOffer } from "@material-ui/icons";
import Register from "./Register";
const bullets = [
	"Sell to 100+ Corporates",
	"Get a Free Subscription Management Platform",
	"Get Advance Payments",
	"Automate Payment Collection & Refunds",
];
const Hero = (props) => {
	return (
		<Box className='dot' style={styles.root}>
			<Container>
				<Grid container spacing={0} direction='row' style={styles.root}>
					<Grid item xs={12} sm={6} style={{ marginTop: 50 }}>
						<Stack gap={2}>
							<Typography style={styles.hero_heading}>
								Build your Subscription Business
							</Typography>
							<Box
								sx={{
									alignItems: "flex-start",
									justifyContent: "center",
									display: "flex",
									flexDirection: "column",
								}}
							>
								{bullets.map(function (bullet) {
									return (
										<Stack
											direction={"row"}
											style={{
												marginTop: 5,
												alignItems: "center",
												justifyContent: "center",
												display: "flex",
												flexDirection: "row",
											}}
											gap={2}
										>
											<CheckCircleIcon style={styles.check} />
											<Typography style={styles.bullet}>{bullet}</Typography>
										</Stack>
									);
								})}
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={1}></Grid>
					<Grid item xs={12} sm={5}>
						<Box style={styles.hero_image_container}>
							<Register />
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};
export default Hero;
const styles = {
	check: {
		fontSize: 18,
		color: "#57B368",
	},
	hero_button: {
		height: 50,
		borderRadius: 5,
		width: 300,
		backgroundColor: "#000000",
		color: "#ffffff",
		fontFamily: "Poppins",
		fontWeight: "bold",
	},
	bullet: {
		fontFamily: "Poppins",
		fontWeight: "400",
		fontSize: 24,
		color: "#000000",
	},
	root: {
		width: "100%",
		paddingRight: 10,
		minHeight: "60vh",
		alignItems: "flex-start",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	hero_heading: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		fontColor: "#444444",
		fontSize: 50,
	},
	hero_subtitle: {
		fontFamily: "Poppins",
		fontWeight: "500",
		fontColor: "#444444",
		fontSize: 24,
	},
	hero_image_container: {
		maxWidth: "600px",
		maxHeight: "600px",
	},
	hero_image: {
		width: "100%",
		height: "100%",
		objectFit: "contain",
	},
};
