import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import eflogo from "../../../assets/images/logo_efoodia_black.webp";
import { history } from "../../helpers/history";
import { Link, useNavigate } from "react-router-dom";
import { colors } from "../../theme";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetAll } from "../../reducers/userReducer";
import "./Header.css";
import { Adb, Android, Facebook, Instagram, Twitter } from "@material-ui/icons";
import { Card, Grid, IconButton, Menu, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import india from "../../../assets/images/india.png";
import uae from "../../../assets/images/uae.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FloatingButton from "../StyledButtons/FloatingButton";
import MenuSurface from "./MenuSurface";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";

const styles = {
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	titles: {
		fontSize: "1em",
		fontFamily: "Arial",

		textTransform: "none",
		textDecoration: "none",
		marginRight: "2em",
		my: 2,
		display: "block",
		mx: 1,
	},
	icons: {
		color: "#000000",
	},
};

const Header = (props) => {
	const [showMenu, showMenuSuface] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [token, setToken] = useState(
		useSelector((state) => state.user.accessToken)
	);

	const [user, setUser] = useState(useSelector((state) => state.user.user));

	console.log(user);
	const handleLogout = () => {
		dispatch(resetAll());
		setToken("");
		setUser({});
		history.push("/");
		window.location.reload();
	};
	const switchMenu = () => {
		showMenuSuface(!showMenu);
	};
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const menuOptions = [
		// {
		// 	title: "Restaurants",
		// 	id: "Restaurants",
		// },
		// {
		// 	title: "Solutions",
		// 	id: "Solution",
		// 	subheading: "Featured Solutions",
		// 	menuItems: [
		// 		{
		// 			icon: <FastfoodIcon />,
		// 			name: "For Foodies",
		// 		},
		// 		{
		// 			icon: <FastfoodIcon />,
		// 			name: "For Restaurants",
		// 		},
		// 		{
		// 			icon: <FastfoodIcon />,
		// 			name: "For Corporates",
		// 		},
		// 	],
		// },
		{
			title: "Partners",
			id: "Restaurants",
			route: "restaurants",
		},
		{
			title: "About us",
			id: "aboutus",
			route: "about",
		},
	];

	return (
		<Box
			sx={{
				flexGrow: 1,
			}}
		>
			<AppBar
				position='static'
				sx={{
					backgroundColor: "white",
					height: 70,
				}}
				elevation={0}
			>
				<Toolbar
					disableGutters
					sx={{
						maxWidth: "100%",
						flexDirection: "row",
					}}
				>
					<div
						style={{
							flexGrow: 1,
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-start",
							marginLeft: "150px",
						}}
					>
						<Card
							elevation={0}
							style={{
								borderRadius: 3,
								backgroundColor: "transparent",
								width: 200,
								height: 80,
								marginLeft: 0,
								marginTop: 0,
								paddingRight: 30,
							}}
							onClick={() => navigate("/")}
						>
							<img src={eflogo} alt='eflogo' height={"100%"} width={"100%"} />
						</Card>
					</div>
					{menuOptions.map((item) => (
						<Link
							className='header-bold'
							style={{
								...styles.titles,
								color: "#444444",
								fontSize: "1.7em",
								//border: "1px solid red",
							}}
							to={`${item.route}`}
						>
							<h6>{item.title}</h6>
						</Link>
						// <div>
						// 	<Typography
						// 		style={{
						// 			...styles.titles,
						// 			color: "#444444",
						// 			fontSize: "1.2em",
						// 			cursor: "pointer",
						// 			//border: "1px solid red",
						// 		}}
						// 		// onClick={handleClick}
						// 	>
						// 		{item.title}
						// 	</Typography>
						// 	<Menu
						// 		id='basic-menu'
						// 		anchorEl={anchorEl}
						// 		marginThreshold={0}
						// 		elevation={0}
						// 		PaperProps={{
						// 			style: {
						// 				width: "100%",
						// 				maxWidth: "100%",
						// 				left: 0,
						// 				right: 0,
						// 				marginTop: 38,
						// 				backgroundColor: "#fafafa",
						// 			},
						// 		}}
						// 		anchorOrigin={{ vertical: "bottom" }}
						// 		transformOrigin={{ vertical: "top" }}
						// 		open={open}
						// 		onClose={handleClose}
						// 		MenuListProps={{
						// 			"aria-labelledby": "basic-button",
						// 		}}
						// 	>
						// 		<Grid container spacing={10} direction={"row"}>
						// 			<Grid item xs={10}>
						// 				<Typography
						// 					style={{
						// 						color: "#07A963",
						// 						fontFamily: "Arial",
						// 						fontSize: "1.2em",
						// 					}}
						// 				>
						// 					{item.subheading}
						// 				</Typography>
						// 			</Grid>
						// 			<Grid item xs={2}>
						// 				Second Half
						// 			</Grid>
						// 		</Grid>
						// 	</Menu>
						// </div>
					))}
					<IconButton
						onClick={() =>
							window.open(
								"https://apps.apple.com/in/app/encyclofoodia/id6456944295",
								"_blank"
							)
						}
					>
						<AppleIcon sx={styles.icons} />
					</IconButton>
					<IconButton
						onClick={() =>
							window.open(
								"https://play.google.com/store/apps/details?id=com.encyclofoodia.shop",
								"_blank"
							)
						}
					>
						<AndroidIcon sx={styles.icons} />
					</IconButton>

					{/* <Box style={{ marginLeft: 10, float: "right" }}>
						<Button
							elevation={0}
							style={{
								width: "100%",
								height: 30,
								//marginLeft: 30,
								padding: 5,
								color: "#444444",
								fontWeight: "bold",
							}}
						>
							Sign in
						</Button>
					</Box> */}
					<Box
						elevation={0}
						style={{
							//backgroundColor: "white",
							width: 125,
							height: 38,
							marginLeft: 10,
							marginRight: 130,
							//border: "1px solid red",
							//borderRadius: 5,
						}}
					>
						<TextField
							// label="Select country"
							select
							defaultValue={"UAE"}
							style={{
								//border: "1px solid red",
								width: 80,
								backgroundColor: "white",
								borderRadius: 5,
								fontWeight: "bold",
							}}
						>
							<MenuItem value='IN' style={{ fontWeight: "bold", marginTop: 5 }}>
								<Avatar
									src={india}
									style={{
										width: 26,
										height: 26,
										marginTop: 0,
										// marginLeft: 2,
									}}
								></Avatar>
							</MenuItem>
							<MenuItem
								value='UAE'
								style={{ fontWeight: "bold", marginTop: 5 }}
							>
								<Avatar
									src={uae}
									style={{
										width: 25,
										height: 25,
										marginLeft: 0,
									}}
								></Avatar>
							</MenuItem>
						</TextField>
					</Box>
				</Toolbar>
			</AppBar>

			<FloatingButton />
		</Box>
	);
};

export default withStyles(styles)(Header);
