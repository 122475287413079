import { ThemeProvider } from "@emotion/react";
import theme from "../common/theme";
import { Typography } from "@mui/material";

function Links() {
  return (
    <ThemeProvider theme={theme}>
      <div className="privacy-policy-container">
      
        <br />
        <br />
  
        <a href="https://dcore-dev-1.sheltoncyril.io/goToStore/ST50" target={'_blank'}>Go to Store with ID ST50</a>
      </div>
    </ThemeProvider>
  );
}

export default Links;
