import { Check } from "@material-ui/icons";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const styles = {
  title: {
    fontFamily: "Poppins",
    color: "#37bbf6",
    fontSize: "28px",
    fontWeight: "600",
  },
  titleXs: {
    fontFamily: "Poppins",
    color: "#37bbf6",
    fontSize: "20px",
    fontWeight: "600",
  },
  communityXs: {
    fontFamily: "Poppins",
    color: "#37bbf6",
    fontSize: "12px",
    fontWeight: "400",
  },
  community: {
    fontFamily: "Poppins",
    color: "#37bbf6",
    fontSize: "18px",
    fontWeight: "400",
  },
  hashtag: {
    fontFamily: "Poppins",
    color: "#37bbf6",
    fontSize: "32px",
    fontWeight: "bold",
  },
  hashtagXs: {
    fontFamily: "Poppins",
    color: "#37bbf6",
    fontSize: "24px",
    fontWeight: "bold",
  },
};
const Community = (props) => {
  return (
    <div>
      <Paper
        style={{
          width: "100%",
          backgroundColor: "transparent",
          borderColor: "#444444",
        }}
        elevation={0}
      >
        <Stack
          direction={"row"}
          gap={1}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
            bgcolor: "transparent",
            display: { md: "flex", xs: "none" },
          }}
        >
          <Typography style={styles.title}>Join the conversation</Typography>
          <Typography style={styles.community}>Efoodia Community</Typography>
        </Stack>
        <Stack
          direction={"row"}
          gap={1}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
            bgcolor: "transparent",
            display: { md: "none", xs: "flex" },
          }}
        >
          <Typography style={styles.titleXs}>Join the conversation</Typography>
          <Typography style={styles.communityXs}>Efoodia Community</Typography>
        </Stack>
        <Divider />
        <Grid
          container
          spacing={2}
          sx={{ p: 2 }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              style={styles.hashtag}
              display={{ md: "flex", xs: "none" }}
            >
              #ConqueryourLifestyle
            </Typography>
            <Typography
              style={styles.hashtagXs}
              display={{ md: "none", xs: "flex" }}
            >
              #ConqueryourLifestyle
            </Typography>
            <Stack>
              <Button
                style={{
                  backgroundColor: "#37bbf6",
                  color: "#ffffff",
                  borderRadius: 10,
                  fontSize: "12px",
                  padding: 10,
                  marginTop: 5,
                  maxWidth: "80%",
                }}
                onClick={() =>
                  window.open(
                    "https://www.skool.com/encyclofoodia-6020?invite=38f7bf6222ab4fd39da3f9279f4d4687",
                    "_blank"
                  )
                }
              >
                Join FREE Efoodia Community
              </Button>
              <Grid container spacing={2} style={{ marginTop: 10 }}>
                <Grid item>
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: 12,
                      color: "#444444",
                    }}
                  >
                    <Check
                      style={{
                        fontSize: "11px",
                        color: "#37bbf6",
                        marginRight: 2,
                      }}
                    />
                    Daily Nutrition Tips
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: 12,
                      color: "#444444",
                    }}
                  >
                    <Check
                      style={{
                        fontSize: "12px",
                        color: "#37bbf6",
                        marginRight: 2,
                      }}
                    />
                    Free Nutritionist 1:1 calls
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: 11,
                      color: "#444444",
                    }}
                  >
                    <Check
                      style={{
                        fontSize: "11px",
                        color: "#37bbf6",
                        marginRight: 2,
                      }}
                    />
                    Free Life Coaching
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src="https://ik.imagekit.io/efoodia/Web/1.png?updatedAt=1705145233696"
              width={"100%"}
              alt="Efoodia Community"
              title="Efoodia Community"
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Community;
