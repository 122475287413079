import api from "../../../api/core";

export default class Sales {
	constructor() {
		this.lead = null;
		this.baseUrl = "/sle/v1/sale-leads";
	}

	create_lead = (payload) => {
		return new Promise((resolve, reject) => {
			api
				.post(`${this.baseUrl}`, payload)
				.then((response) => {
					this.lead = response.data.data;
					return resolve(this.lead);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	};
}
