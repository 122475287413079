import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Check } from "@material-ui/icons";
import DownloadApp from "../AppIcons/DownloadApp";
import Play from "../../../assets/images/google_play.png";
import Appstore from "../../../assets/images/appstore.png";
import Web from "../../../assets/images/web.png";

const offerArray = [
  {
    id: 1,
    title: "50% off",
    title2: "On Any Meal plan",
    coupon: "HELLO50",
    maxDiscount: 100,
    minOrderValue: 400,
    canCombine: false,
    participants: ["All Plans"],
    minOrderDays: 5,
    description: "Avail 50% discount on any meal plan",
    color: "#37BBF6",
    img: "https://ik.imagekit.io/efoodia/Web/1.png?updatedAt=1705691545462",
  },
  {
    id: 2,
    title: "50% off",
    title2: "on Freshly Cooked High protien low carb diet plan",
    coupon: "GREEN50",
    maxDiscount: 210,
    minOrderValue: 400,
    canCombine: false,
    participants: ["Green Plates"],
    minOrderDays: 5,
    color: "#53B022",
    description:
      "Avail 50% discount on any meal plan from Green Plates. Get 3 times meal for AED 35/day",
    img: "https://ik.imagekit.io/efoodia/Web/2.png?updatedAt=1705691545243",
  },
];
const styles = {
  title: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    color: "#4444444",
  },
  title2: {
    fontFamily: "Poppins",
    fontWeight: "400",
    color: "#444444",
    fontSize: 12,
  },
  description: {
    fontFamily: "Poppins",
    fontWeight: "400",
    color: "#444444",
    fontSize: 14,
  },
  coupon: {
    fontFamily: "Poppins",
    fontWeight: "400",
    color: "#37BBF6",
    fontSize: 18,
  },
};

const Offers = (props) => {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    setOffers(offerArray);
  }, []);

  return (
    <Box style={{ minHeight: "80vh", marginTop: 30 }}>
      <Grid
        container
        sx={{ display: "flex" }}
        flexDirection={"row"}
        spacing={1}
      >
        <Grid item xs={12} md={6}>
          <Stack sx={{ p: 1 }} gap={1}>
            <Typography
              style={{
                fontFamily: "Poppins",
                fontWeight: "Bold",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              To Avail the offer
            </Typography>
            <Typography style={styles.description}>
              <Check
                style={{
                  fontSize: "14px",
                  color: "#37BBF6",
                  marginRight: 5,
                }}
              />
              Download the Efoodia app
            </Typography>
            <Typography style={styles.description}>
              <Check
                style={{
                  fontSize: "14px",
                  color: "#37BBF6",
                  marginRight: 5,
                }}
              />
              Select your store, and subscription plan
            </Typography>
            <Typography style={styles.description}>
              <Check
                style={{
                  fontSize: "14px",
                  color: "#37BBF6",
                  marginRight: 5,
                }}
              />
              At the checkout screen use the coupon given to avail the offer
            </Typography>
            <Stack>
              <Typography
                variant="h5"
                style={{ fontFamily: "Poppins", marginTop: 10 }}
              >
                Download App
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  width: "100%",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={Play}
                  width={120}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.encyclofoodia.shop",
                      "_blank"
                    )
                  }
                />

                <img
                  src={Appstore}
                  width={100}
                  style={{ cursor: "pointer", marginTop: 3 }}
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/in/app/encyclofoodia/id6456944295",
                      "_blank"
                    )
                  }
                />
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={Web} alt="app-image" title="app-image" width={"100%"} />
        </Grid>
        {offers.map((offer) => {
          return (
            <Grid item xs={12} md={6} style={{ backgroundColor: "#fafafa" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <img src={offer.img} width={"100%"} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack>
                    <Typography style={styles.title}>{offer.title}</Typography>
                    <Typography style={styles.title2}>
                      {offer.title2}
                    </Typography>

                    <Stack gap={1}>
                      <Box style={{ minHeight: 40 }}>
                        <Typography style={styles.description}>
                          {offer.description}
                        </Typography>
                      </Box>

                      <Typography
                        style={{
                          color: "#37BBF6",
                          fontFamily: "Poppins",
                          fontSize: 16,
                          fontWeight: "bold",
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        Conditions
                      </Typography>
                      <Typography style={styles.description}>
                        <Check
                          style={{
                            fontSize: "14px",
                            color: offer.color,
                            marginRight: 5,
                          }}
                        />
                        Max Disount : <strong> AED {offer.maxDiscount}</strong>
                      </Typography>
                      <Typography style={styles.description}>
                        <Check
                          style={{
                            fontSize: "14px",
                            color: offer.color,
                            marginRight: 5,
                          }}
                        />
                        Can't Combine with other offers
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
          );
        })}

        {/* {offers.map((offer) => {
				return (
					<Grid item xs={12} md={4}>
						<Card
							style={{
								height: "40vh",
								padding: 3,
							}}
						>
							<CardHeader
								style={{ backgroundColor: offer.color }}
								title={
									<Typography style={styles.title}>{offer.title}</Typography>
								}
								subheader={
									<Typography style={styles.title2}>{offer.title2}</Typography>
								}
							/>
							<CardContent>
								<Stack gap={1}>
									<Box style={{ minHeight: 40 }}>
										<Typography style={styles.description}>
											{offer.description}
										</Typography>
									</Box>

									<Typography
										style={{
											color: "#37BBF6",
											fontFamily: "Poppins",
											fontSize: 16,
											fontWeight: "bold",
											marginTop: 5,
											marginBottom: 5,
										}}
									>
										Conditions
									</Typography>
									<Typography style={styles.description}>
										<Check
											style={{
												fontSize: "14px",
												color: offer.color,
												marginRight: 5,
											}}
										/>
										Max Disount : <strong> AED {offer.maxDiscount}</strong>
									</Typography>
									<Typography style={styles.description}>
										<Check
											style={{
												fontSize: "14px",
												color: offer.color,
												marginRight: 5,
											}}
										/>
										Can't Combine with other offers
									</Typography>
									<Paper
										elevation={0}
										style={{
											border: "1px dotted",
											padding: 5,
											alignItems: "center",
											justifyContent: "ceneter",
											display: "flex",
											flexDirection: "column",

											alignSelf: "center",
										}}
									>
										<Typography style={styles.coupon}>
											{offer.coupon}
										</Typography>
									</Paper>
								</Stack>
							</CardContent>
						</Card>
					</Grid>
				);
			})} */}
      </Grid>
    </Box>
  );
};
export default Offers;
