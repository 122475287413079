import React, { useEffect, useState } from "react";
import "./Customer.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../../theme";
import { Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { history } from "../../helpers/history";
import catalog from "../../../api/catalog";
import _ from "lodash";
import "./CouponTag.css";
const Customer = (props) => {
  const [token, setToken] = useState(
    useSelector((state) => state.user.accessToken)
  );
  console.log(token);
  const [user, setUser] = useState(useSelector((state) => state.user.user));
  console.log(user);
  const [coupons, setCoupons] = useState([]);

  const CouponTag = ({ coupons }) => {
    const renderCouponValue = () => {
      if (coupons.couponType === "percentage_cart") {
        return `${coupons.couponValue.value}% OFF`;
      } else if (coupons.couponType === "flat_cart") {
        return `${coupons.couponValue.value} OFF`;
      } else {
        return "Invalid Coupon Type";
      }
    };

    return (
      <div className="coupon-tag">
        <div className="coupon-code">{coupons.couponCode}</div>
        <div className="coupon-description">{coupons.description}</div>
        <div className="coupon-value">{renderCouponValue()}</div>
      </div>
    );
  };

  useEffect(() => {
    //console.log(user);
    const params = {
      userId: user.userId,
      couponStatus: "active",
    };
    catalog
      .getCoupons(params, token)
      .then((res) => {
        const newList = _.map(res, (coupon) => {
          if (coupon.couponLevel == "all") coupon.couponLevel = "Global";
          else if (coupon.couponLevel == "store") coupon.couponLevel = "Store";
          else if (coupon.couponLevel == "organisation")
            coupon.couponLevel = "Organisation";
          return coupon;
        });
        setCoupons(newList);
        // setCoupons(res);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(coupons);
  return (
    <ThemeProvider theme={theme}>
      <div className="privacy-policy-container">
        <Typography variant="h3" className="header">
          My Coupons
        </Typography>
        <br />
        <br />
        <div className="coupons-container">
          {coupons.map((coupons, index) => (
            <CouponTag coupons={coupons} key={index} />
          ))}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Customer;
