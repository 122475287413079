import axios from "axios";
const community = axios.create({
	baseURL: "https://api.skool.com/groups/encyclofoodia-6020/",
	headers: {
		"Content-Type": "application/json",
		accept: "*/*",
	},
});

community.defaults.timeout = 25000;

function register_to_community(email) {
	return new Promise((resolve, reject) => {
		community
			.post(`webhooks/69eaea2651b44cfabed0cfc9a0f6ebe9?email=${email}`)
			.then((response) => {
				return resolve(response.data.json());
			})
			.catch((err) => {
				return reject(err);
			});
	});
}
export default register_to_community;
