import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
	status: {
		danger: "#e53e3e",
	},
	palette: {
		primary: {
			main: "#0FA739",
		},
		neutral: {
			main: "#64748B",
			contrastText: "#fff",
		},
		secondary: {
			main: "#3f3232",
		},
	},
	typography: {
		h3: {
			color: "#3f3232",
			fontFamily: [
				"Nunito",
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
		},
		h4: {
			color: "#3f3232",
			fontFamily: [
				"Nunito",
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
		},
		h5: {
			color: "#3f3232",
			fontFamily: [
				"Nunito",
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
		},
		h6: {
			color: "#00000",
			fontWeight: 300,
			fontFamily: [
				"Nunito",
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
		},
	},
});
theme = responsiveFontSizes(theme);

export const colors = {
	green: "#0FA739",
};
export default theme;
