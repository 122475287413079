import React, { useState, useEffect } from "react";
import { Button, Container, Typography } from "@mui/material";

const BikerGame = () => {
	const [canvas, setCanvas] = useState(null);
	const [ctx, setCtx] = useState(null);

	const [biker, setBiker] = useState({
		x: 50,
		y: 400 - 100,
		width: 50,
		height: 50,
		speed: 5,
		jumpHeight: 400 - 10,
		jumpCount: 0,
		jumping: false,
	});

	const [hurdle, setHurdle] = useState({
		x: 800 - 50,
		y: 400 - 50,
		width: 30,
		height: 30,
		speed: 5,
	});

	const [hurdle2, setHurdle2] = useState({
		x: 800 + 200,
		y: 400 - 50,
		width: 30,
		height: 30,
		speed: 5,
	});

	const [score, setScore] = useState(0);
	const [gameStarted, setGameStarted] = useState(false);
	const [gameOver, setGameOver] = useState(false);

	useEffect(() => {
		const canvasElement = document.getElementById("gameCanvas");
		const context = canvasElement.getContext("2d");
		setCanvas(canvasElement);
		setCtx(context);
	}, []);

	const drawBiker = () => {
		// Replace the placeholder text with the actual SVG code of your biker image
		const bikerSvg = `
      <!-- Actual SVG code of your biker image goes here -->
      <svg xmlns="http://www.w3.org/2000/svg" width="${biker.width}" height="${biker.height}" viewBox="0 0 50 50">
        <rect width="50" height="50" fill="#0095DD"/>
        <!-- Add more SVG elements for the biker image -->
      </svg>
    `;
		const img = new Image();
		img.src = "data:image/svg+xml," + encodeURIComponent(bikerSvg);

		ctx.drawImage(img, biker.x, biker.y, biker.width, biker.height);
	};

	const drawHurdle = (hurdle) => {
		ctx.fillStyle = "#FF0000";
		ctx.fillRect(hurdle.x, hurdle.y, hurdle.width, hurdle.height);
	};

	const drawScore = () => {
		ctx.font = "20px Arial";
		ctx.fillStyle = "#0095DD";
		ctx.fillText("Score: " + score, 10, 30);
	};

	const draw = () => {
		if (!gameStarted) {
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.font = "30px Arial";
			ctx.fillStyle = "#0095DD";
			ctx.fillText(
				"Press Space Bar to Start",
				canvas.width / 4,
				canvas.height / 2
			);
			return;
		}

		if (gameOver) {
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.font = "30px Arial";
			ctx.fillStyle = "#0095DD";
			ctx.fillText("Game Over", canvas.width / 2 - 100, canvas.height / 2);
			ctx.fillText(
				"Score: " + score,
				canvas.width / 2 - 60,
				canvas.height / 2 + 40
			);
			return;
		}

		ctx.clearRect(0, 0, canvas.width, canvas.height);

		drawBiker();
		drawHurdle(hurdle);
		drawHurdle(hurdle2);
		drawScore();

		if (biker.jumping) {
			if (biker.y > canvas.height - biker.jumpHeight) {
				setBiker({
					...biker,
					y: biker.y - biker.speed,
					jumpCount: biker.jumpCount + 1,
				});

				if (biker.jumpCount > biker.jumpHeight) {
					setBiker({ ...biker, jumping: false, jumpCount: 0 });
				}
			} else {
				setBiker({ ...biker, jumping: false, jumpCount: 0 });
			}
		} else if (biker.y < canvas.height - biker.height) {
			setBiker({ ...biker, y: biker.y + biker.speed });
		}

		setHurdle({ ...hurdle, x: hurdle.x - hurdle.speed });
		setHurdle2({ ...hurdle2, x: hurdle2.x - hurdle2.speed });

		if (hurdle.x + hurdle.width < 0) {
			setHurdle({
				...hurdle,
				x: canvas.width - hurdle.width,
				y: Math.floor(Math.random() * (canvas.height - hurdle.height)),
			});
			setScore(score + 1);
		}

		if (hurdle2.x + hurdle2.width < 0) {
			setHurdle2({
				...hurdle2,
				x: canvas.width - hurdle2.width,
				y: Math.floor(Math.random() * (canvas.height - hurdle2.height)),
			});
			setScore(score + 1);
		}

		if (
			(biker.x < hurdle.x + hurdle.width &&
				biker.x + biker.width > hurdle.x &&
				biker.y < hurdle.y + hurdle.height &&
				biker.y + biker.height > hurdle.y) ||
			(biker.x < hurdle2.x + hurdle2.width &&
				biker.x + biker.width > hurdle2.x &&
				biker.y < hurdle2.y + hurdle2.height &&
				biker.y + biker.height > hurdle2.y)
		) {
			setGameOver(true);
		}

		requestAnimationFrame(draw);
	};

	const jump = () => {
		if (!biker.jumping) {
			setBiker({ ...biker, jumping: true });
		}
	};

	const handleKeyPress = (event) => {
		console.log("pressed", event.key);
		if (event.key === " ") {
			if (!gameStarted) {
				setGameStarted(true);
				draw();
			} else {
				jump();
			}
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleKeyPress);

		return () => {
			document.removeEventListener("keydown", handleKeyPress);
		};
	}, [gameStarted, biker.jumping, gameOver]);

	return (
		<Container>
			<canvas id='gameCanvas' width='800' height='400'></canvas>
			{!gameStarted && (
				<Typography variant='h4' style={{ marginTop: "20px" }}>
					Press Space Bar to Start
				</Typography>
			)}
			{gameOver && (
				<div>
					<Typography variant='h4' style={{ marginTop: "20px" }}>
						Game Over
					</Typography>
					<Typography variant='h6'>Score: {score}</Typography>
				</div>
			)}
		</Container>
	);
};

export default BikerGame;
