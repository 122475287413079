import {
	Avatar,
	Box,
	Button,
	Chip,
	Divider,
	Grid,
	IconButton,
	InputBase,
	Paper,
	Stack,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 30,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(9px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(12px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.mode === "dark" ? "#53B022" : "#53B022",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: "#C51A6F",
		boxSizing: "border-box",
	},
}));
const styles = {
	title: {
		FontFamily: "Poppins",
		fontWeight: "bold",
		fontSize: "18px",
		color: "#4E5462",
	},
	input: {
		maxWidth: 80,
	},
	current: {
		color: "#53B022",
		cursor: "pointer",
		borderColor: "#53B022",
		fontFamily: "Poppins",
		fontWeight: "bold",
	},
	inactive: {
		cursor: "pointer",
		color: "#444444",
		cursor: "pointer",
		borderColor: "#444444",
		fontFamily: "Poppins",
		fontWeight: "400",
	},
	caption: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		color: "#444444",
		fontSize: "18px",
	},
	lose_wt: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		color: "#53B022",
		fontSize: "22px",
	},
	gain_weight: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		color: "#e7ae73",
		fontSize: "22px",
	},
	maintain: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		color: "#5a8897",
		fontSize: "22px",
	},
};
const activity_levels = ["No Activity", "Low", "Moderate", "Active"];
const CalorieCalculator = (props) => {
	const [activityLevel, setActivityLevel] = useState(2);
	const [height, setHeight] = useState(166);
	const [weight, setWeight] = useState(70);
	const [age, setAge] = useState(40);
	const [fat, setFat] = useState(20);
	const [gender, setGender] = useState("male");
	const [result, setResult] = useState({});
	const [target, setTarget] = useState(64);
	const [months, setMonths] = useState(3);

	function calculateCalorieIntake(
		age,
		gender,
		height,
		weight,

		activity,
		targetWeight,
		monthsForTransformation
	) {
		// Calculate BMR based on gender
		let bmr = 0;
		if (gender.toLowerCase() === "male") {
			bmr = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
		} else if (gender.toLowerCase() === "female") {
			bmr = 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
		} else {
			return "Invalid gender";
		}

		// Calculate TDEE based on activity level
		const activityFactors = {
			"no activity": 1.2,
			low: 1.375,
			moderate: 1.55,
			active: 1.725,
		};

		if (!activityFactors.hasOwnProperty(activity.toLowerCase())) {
			return "Invalid activity level";
		}

		const tdee = bmr * activityFactors[activity.toLowerCase()];

		// Calculate calorie intake for weight loss, weight gain, and maintenance
		const weightLossCalories = tdee - 500; // 500 calorie deficit for weight loss
		const weightMaintenanceCalories = tdee;
		const weightGainCalories = tdee + 500; // 500 calorie surplus for weight gain

		// Adjust calorie intake for the target weight and transformation period
		const transformationFactor =
			(targetWeight - weight) / (monthsForTransformation * 4); // Assuming 4 weeks in a month

		const adjustedWeightLossCalories =
			weightLossCalories + transformationFactor;
		const adjustedWeightMaintenanceCalories =
			weightMaintenanceCalories + transformationFactor;
		const adjustedWeightGainCalories =
			weightGainCalories + transformationFactor;

		return {
			adjustedWeightLoss: adjustedWeightLossCalories.toFixed(2),
			adjustedWeightMaintenance: adjustedWeightMaintenanceCalories.toFixed(2),
			adjustedWeightGain: adjustedWeightGainCalories.toFixed(2),
		};
	}

	// Example usage:

	// Example usage:
	// const result = calculateCalorieIntake(30, "male", 175, 80, 25, "moderate");

	const handleCalculate = () => {
		const res = calculateCalorieIntake(
			age,
			gender,
			height,
			weight,
			activity_levels[activityLevel],
			target,
			months
		);
		console.log(res);
		setResult(res);
	};
	const handleGenderChange = (event) => {
		if (event.target.checked) setGender("male");
		else setGender("female");
	};
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} md={2}>
				<Stack>
					<Typography style={styles.title}>Gender</Typography>
					<Stack direction='row' spacing={1} alignItems='center'>
						<Typography>Female</Typography>
						<AntSwitch
							defaultChecked
							onChange={(event) => handleGenderChange(event)}
							inputProps={{ "aria-label": "ant design" }}
						/>
						<Typography>Male</Typography>
					</Stack>
				</Stack>
			</Grid>
			<Grid item>
				<Stack alignItems='center'>
					<Typography style={styles.title}>Height & Weight</Typography>

					<Stack direction='row' spacing={1} alignItems='center'>
						<TextField
							placeholder='CM'
							style={styles.input}
							onChange={(e) => setHeight(e.target.value)}
							value={height}
						/>
						<TextField
							placeholder='Kg'
							style={styles.input}
							onChange={(e) => setWeight(e.target.value)}
							value={weight}
						/>
					</Stack>
				</Stack>
			</Grid>
			<Grid item>
				<Stack alignItems='center'>
					<Typography style={styles.title}>Age</Typography>
					<Stack direction='row' alignItems='center'>
						<TextField
							placeholder='ex.40'
							style={styles.input}
							onChange={(e) => setAge(e.target.value)}
							value={age}
						/>
					</Stack>
				</Stack>
			</Grid>
			<Grid item>
				<Stack alignItems='center'>
					<Typography style={styles.title}>Target Wt & Months</Typography>

					<Stack direction='row' spacing={1} alignItems='center'>
						<TextField
							placeholder='Kg'
							style={styles.input}
							onChange={(e) => setTarget(e.target.value)}
							value={target}
						/>
						<TextField
							placeholder='Months'
							style={styles.input}
							onChange={(e) => setMonths(e.target.value)}
							value={months}
						/>
					</Stack>
				</Stack>
			</Grid>

			<Grid item>
				<Stack alignItems='center'>
					<Typography style={styles.title}>How Active are You?</Typography>
					<Stack direction='row' spacing={1} alignItems='center'>
						{activity_levels.map((level, id) => {
							return (
								<div onClick={() => setActivityLevel(id)}>
									<Chip
										label={level}
										variant='outlined'
										style={
											id == activityLevel ? styles.current : styles.inactive
										}
									/>
								</div>
							);
						})}
					</Stack>
				</Stack>
			</Grid>
			<Grid
				item
				sx={{
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
				}}
			>
				<Stack>
					<Button
						style={{
							color: "#ffffff",
							width: "100%",
							backgroundColor: "#53B022",
						}}
						onClick={handleCalculate}
					>
						Calculate
					</Button>
				</Stack>
			</Grid>

			<Box width={"100%"} sx={{ p: 1, mt: 2 }} alignItems='center'>
				<Stack direction='row' spacing={1} alignItems='center'>
					<Grid container>
						<Grid item xs={12} md={12}>
							<Stack alignItems='center'>
								<i
									class='las la-weight'
									style={{ fontSize: 40, color: styles.lose_wt.color }}
								></i>
								<Typography style={styles.caption}>Lose Wt</Typography>
								<Typography style={styles.lose_wt}>
									{result.adjustedWeightLoss}
								</Typography>
							</Stack>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} md={12}>
							<Stack alignItems='center'>
								<i
									class='las la-dumbbell'
									style={{ fontSize: 40, color: styles.gain_weight.color }}
								></i>
								<Typography style={styles.caption}>Gain Wt</Typography>
								<Typography style={styles.gain_weight}>
									{result.adjustedWeightGain}
								</Typography>
							</Stack>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} md={12}>
							<Stack alignItems='center'>
								<i
									class='las la-arrows-alt-h'
									style={{ fontSize: 40, color: styles.maintain.color }}
								></i>
								<Typography style={styles.caption}>Maintain</Typography>
								<Typography style={styles.maintain}>
									{result.adjustedWeightMaintenance}
								</Typography>
							</Stack>
						</Grid>
					</Grid>
				</Stack>
			</Box>
		</Grid>
	);
};
export default CalorieCalculator;
