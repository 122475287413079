import "./Restaurant.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../../theme";
import ButtonYellow from "../ButtonGreen/ButtonGreen";
import eating_together from "../../../assets/images/eating_together.png";
import phone from "../../../assets/images/phone.png";
import find from "../../../assets/images/find.png";
import { Avatar } from "@material-ui/core";
import brightness from "../../../assets/images/brightness.png";
import meal from "../../../assets/images/meal.png";
import papa from "../../../assets/images/papa.png";
import Tok from "../../../assets/images/TOK.png";
import dailymeal from "../../../assets/images/dailymeal.png";
import moon from "../../../assets/images/moon.png";
import {
	Card,
	CardActionArea,
	Grid,
	InputAdornment,
	SvgIcon,
	TextField,
} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import TextBox from "../TextBox/TextBox";
import { useEffect, useState } from "react";
import computer from "../../../assets/images/computer.png";
import astronaut from "../../../assets/images/astronaut.jpg";

function Restaurant() {
	function createData(breakdown, monthly, yearly) {
		return { breakdown, monthly, yearly };
	}

	const [dailyOrders, setDailyOrders] = useState(10);
	const [avgValue, setAvgValue] = useState(480);
	const [foodCostPercent, setFoodCostPercent] = useState(30);
	const [labourCost, setLabourCost] = useState(0);
	const [refresh, setRefresh] = useState(0);
	const [rows, setRows] = useState([]);
	const deliveryFee = 30;

	useEffect(() => {
		let projectedIncome = avgValue * dailyOrders * 30;
		let efFee = 0.05 * avgValue * dailyOrders * 30;
		let delivery = deliveryFee * dailyOrders * 30;
		let foodcost = (foodCostPercent / 100) * projectedIncome;
		let laborcost = labourCost * dailyOrders * 30;
		const rowList = [
			createData(
				"Projected income for EF orders",
				"\u20B9" + projectedIncome,
				"\u20B9" + projectedIncome * 12
			),
			createData(
				"EF facilitation fee (-)",
				"\u20B9" + efFee,
				"\u20B9" + efFee * 12
			),
			createData(
				"Delivery fee (Avg) (+)",
				"\u20B9" + delivery,
				"\u20B9" + delivery * 12
			),
			createData(
				"Food cost (-)",
				"\u20B9" + foodcost,
				"\u20B9" + foodcost * 12
			),
			createData(
				"Additional labor cost (-)",
				"\u20B9" + laborcost,
				"\u20B9" + laborcost * 12
			),
			createData(
				"Projected incremented profit",
				"\u20B9" + (projectedIncome - efFee + delivery - foodcost - laborcost),
				"\u20B9" +
					(projectedIncome - efFee + delivery - foodcost - laborcost) * 12
			),
			createData(
				"Profit (%)",
				Math.round(
					((projectedIncome - efFee + delivery - foodcost - laborcost) /
						projectedIncome) *
						100
				) + "%",
				Math.round(
					(((projectedIncome - efFee + delivery - foodcost - laborcost) * 12) /
						(projectedIncome * 12)) *
						100
				) + "%"
			),
		];
		setRows(rowList);
	}, [refresh]);

	return (
		<Grid
			container
			spacing={2}
			direction='row'
			style={{ marginTop: 10, backgroundColor: "white" }}
		>
			<Grid item>
				<hr
					style={{
						width: 55,
						border: "4px solid black",
						marginLeft: 680,
						marginTop: 0,
					}}
				></hr>
				<h1 style={{ fontSize: 90, marginLeft: 50, marginTop: 130 }}>
					Let's Build<br></br> Your<br></br> Subscription<br></br> Business.
				</h1>
			</Grid>
			<Grid item>
				<Card
					elevation={0}
					className='card'
					style={{ marginTop: 10, width: 300 }}
				>
					<CardContent
						className='card-content'
						style={{
							background: "white",
							color: "black",
							height: 330,
							fontSize: "1.35em",
							height: 300,
						}}
					>
						<Avatar
							variant='square'
							src={find}
							sx={{
								height: 6,
								width: 6,
							}}
						></Avatar>
						<h2
							style={{
								fontWeight: "600",
								textAlign: "left",
								marginTop: 5,
							}}
						>
							Increase Revenue
						</h2>

						<Typography
							className='card-description'
							style={{
								color: "black",
								fontSize: 19,
								textAlign: "left",
								marginLeft: -20,
								marginTop: -25,
							}}
						>
							Reach to thousands of encyclofoodia executive meal customers, and
							let them subscribe to your exiting menu
						</Typography>
					</CardContent>
				</Card>
			</Grid>
			<Grid item>
				<Card
					elevation={0}
					className='card'
					style={{ marginTop: 10, width: 300, marginLeft: 50 }}
				>
					<CardContent
						className='card-content'
						style={{
							background: "white",
							color: "black",
							height: 280,
							fontSize: 22,
						}}
					>
						<Avatar
							variant='square'
							src={brightness}
							sx={{
								height: 6,
								width: 6,
							}}
						></Avatar>
						<h2
							style={{
								fontWeight: "600",
								textAlign: "left",
							}}
						>
							Cashflow
						</h2>

						<Typography
							className='card-description'
							style={{
								color: "black",
								fontSize: 19,
								textAlign: "left",
								marginLeft: -20,
								marginTop: -5,
							}}
						>
							Build a steady cashflow using Encyclofoodia FREE platform by
							partnering as a meal provider.
						</Typography>
					</CardContent>
				</Card>
			</Grid>
			<Grid item>
				<Card
					elevation={0}
					className='card'
					style={{ marginTop: -210, width: 300, marginLeft: 780 }}
				>
					<CardContent
						className='card-content'
						style={{
							background: "white",
							color: "black",
							height: 280,
							fontSize: 22,
						}}
					>
						<Avatar
							variant='square'
							src={find}
							sx={{
								height: 6,
								width: 6,
							}}
						></Avatar>
						<h2
							style={{
								fontWeight: "600",
								textAlign: "left",
							}}
						>
							Prevent Leaks
						</h2>

						<Typography
							className='card-description'
							style={{
								color: "black",
								fontSize: 19,
								textAlign: "left",
								marginLeft: -20,
								marginTop: -5,
							}}
						>
							Use our technology to manage your subscription business and never
							miss the renewals, payments etc.
						</Typography>
					</CardContent>
				</Card>
			</Grid>

			<Grid item>
				<Card
					elevation={0}
					className='card'
					style={{ marginTop: -215, width: 300, marginLeft: 50 }}
				>
					<CardContent
						className='card-content'
						style={{
							background: "white",
							color: "black",
							height: 280,
							fontSize: 22,
						}}
					>
						<Avatar variant='square' src={brightness}></Avatar>
						<h2
							style={{
								fontWeight: "600",
								textAlign: "left",
							}}
						>
							Market for Free!
						</h2>

						<Typography
							className='card-description'
							style={{
								color: "black",
								fontSize: 19,
								textAlign: "left",
								marginLeft: -20,
								marginTop: -5,
							}}
						>
							We invest in marketing our partners brands through social platform
							and through campaigns
						</Typography>
					</CardContent>
				</Card>
			</Grid>

			<Grid container style={{ backgroundColor: "ghostwhite", marginTop: 80 }}>
				<Grid item>
					<img
						variant='square'
						src={computer}
						style={{
							height: 600,
							width: 600,
							marginLeft: 80,
						}}
					></img>
				</Grid>
				<Grid item>
					<Typography
						style={{
							fontSize: 21.5,
							marginLeft: 300,
							marginTop: 160,
							fontWeight: "600",
						}}
					>
						Encyclofoodia
					</Typography>
					<h2
						style={{
							fontSize: 40,
							fontWeight: "600",
							marginLeft: 230,
							marginTop: 10,
						}}
					>
						Get Bulk Orders
					</h2>
					<Typography
						style={{ fontSize: 21.5, marginLeft: 180, marginTop: -10 }}
					>
						When a customer on Encyclofoodia places<br></br> an order, you get a
						recurring order for<br></br>{" "}
						<span style={{ marginLeft: 120 }}>whole month!</span>
					</Typography>
					{/* <button
						style={{
							border: "2px solid black",
							backgroundColor: "black",
							color: "white",
							fontSize: 19.5,
							fontWeight: "bold",
							borderRadius: 33,
							height: 70,
							width: 200,
							paddingTop: -5,
							marginLeft: 270,
							marginTop: 50,
						}}
					>
						Contact US
					</button> */}
				</Grid>
			</Grid>
			<Grid item direction='row'>
				<img
					src={meal}
					style={{ height: 100, width: 200, marginLeft: 300, marginTop: 300 }}
				></img>{" "}
				<img src={papa} style={{ height: 100, width: 200 }}></img>
				<img src={Tok} style={{ height: 100, width: 200 }}></img>
				<img src={dailymeal} style={{ height: 100, width: 200 }}></img>
				<hr style={{ width: 600, marginLeft: -50 }}></hr>
				<h3
					style={{
						fontSize: 19,
						fontWeight: "600",
						marginLeft: 595,
						marginTop: -20,
					}}
				>
					200+ Partners in Dubai
				</h3>
				<hr style={{ width: 690, marginLeft: 830, marginTop: -30 }}></hr>
			</Grid>
			<h1
				style={{
					fontSize: 85,
					marginTop: 130,
					marginLeft: 250,
					fontWeight: "700",
				}}
			>
				We have Story About our<br></br> Empowered Restaurants
			</h1>
			<Grid item direction='row'>
				<h2 style={{ fontSize: 60, fontWeight: "600", marginLeft: 180 }}>
					Encyclofoodia<br></br> generated AED<br></br> 3.2M in sales in 4
					<br></br> months!
				</h2>
				<hr
					style={{
						width: 300,
						transform: "rotate(90deg)",
						marginLeft: 550,
						marginTop: -200,
					}}
				></hr>
				<Card
					elevation={0}
					style={{
						marginLeft: 880,
						width: 550,
						marginTop: -180,
						//borderLeft: "0.5px solid black ",
					}}
				>
					<h3
						style={{
							fontSize: 26,
							fontWeight: "600",
							marginLeft: 30,
						}}
					>
						It took us couple of days to get around <br></br>the idea of
						Encyclofoodia. When we <br></br>realised that its brand new, less
						<br></br>
						travelled path, we just jumped in<br></br> Today we serve 190+ meals
						through <br></br>encyclofoodia, Every day!.
					</h3>
				</Card>
				<button
					style={{
						width: 160,
						height: 30,
						fontSize: 20,
						fontWeight: "600",
						marginLeft: 880,
						marginTop: 30,
						backgroundColor: "white",
						border: "none",
					}}
				>
					Taste of Kerala
				</button>
			</Grid>
			<Grid item direction='row'>
				<h5
					style={{
						fontSize: 20,
						fontWeight: "600",
						marginLeft: 130,
						marginTop: 200,
					}}
				>
					Offer Flexibility, get loyal customers
				</h5>
				<h2 style={{ fontSize: 60, marginLeft: 130, marginTop: -20 }}>
					Let them Pause,<br></br> and renew<br></br> seamlessly!
				</h2>
				<h3
					style={{
						fontSize: 23.5,
						fontWeight: "normal",
						marginLeft: 130,
						marginTop: -20,
					}}
				>
					Encyclofoodia platform takes care of the<br></br> renewals, payment
					collections.
				</h3>
				{/* <button
					style={{
						border: "2px solid black",
						backgroundColor: "black",
						color: "white",
						fontSize: 19.5,
						fontWeight: "bold",
						borderRadius: 33,
						height: 70,
						width: 200,
						marginLeft: 130,
						marginTop: 50,
					}}
				>
					Contact US
				</button> */}
				<Card
					style={{ height: 650, width: 650, marginLeft: 750, marginTop: -550 }}
				>
					<img
						src={moon}
						style={{
							height: 650,
							width: 650,
							marginTop: 5,
						}}
					></img>
				</Card>
			</Grid>
			<Card
				style={{
					height: 150,
					marginTop: 600,
					marginLeft: -550,
					width: 450,
					borderRadius: 20,
				}}
			>
				<img
					src={astronaut}
					variant='circle'
					style={{
						height: 60,
						width: 60,
						borderRadius: 50,
						marginTop: 40,
						border: "2px solid white",
					}}
				></img>

				<h2
					style={{
						fontSize: 24,
						fontWeight: "bold",
						marginLeft: 80,
						marginTop: -80,
					}}
				>
					Mr. Spaceman
				</h2>
				<h3
					style={{
						fontSize: 23,
						fontWeight: "normal",
						marginLeft: 80,
						marginTop: -20,
					}}
				>
					Hey Encyclofoodia, pause my<br></br> subscription for 3 months
					please??{" "}
				</h3>
			</Card>

			<h3
				style={{
					fontSize: 22,
					fontWeight: "600",
					marginTop: 200,
					marginLeft: 650,
				}}
			>
				Testimonials & Reviews
			</h3>
			<h2 style={{ fontSize: 65, marginLeft: 420, marginTop: -10 }}>
				Some Love From Clients
			</h2>
			<Grid item style={{ marginTop: 50 }}>
				<Grid container spacing={1} direction={"row"}>
					<Grid item>
						<Card
							elevation={0}
							style={{
								width: 340,
								height: 350,
								borderRadius: 15,
								marginLeft: 150,
								backgroundColor: "white",
							}}
						>
							<img
								src={Tok}
								style={{
									height: 65,
									width: 65,
									borderRadius: 50,
									marginTop: 20,
									marginLeft: 10,
								}}
							></img>
							<h3
								style={{
									textAlign: "center",
									marginTop: -60,
									fontSize: 24,
									marginLeft: 10,
									fontWeight: "600",
								}}
							>
								Tony Mamplal
							</h3>
							<h5
								style={{
									textAlign: "right",
									marginTop: -20,
									fontSize: 19.5,
									marginRight: 50,
									fontWeight: "normal",
									color: "black",
									fontStyle: "normal",
								}}
							>
								Manager, Taste of Kerala
							</h5>
							<h2
								style={{
									fontSize: 19.5,
									width: 320,
									fontWeight: "normal",
									fontFamily: "sans-serif",
									lineHeight: 2,
									marginLeft: 10,
								}}
							>
								<span style={{ fontWeight: "bold" }}>“Encyclofoodia </span>
								convinced us to start a subscription business, and I am glad
								that we did. We just used{" "}
								<span style={{ fontWeight: "bold" }}>our existing menu</span>
								to quickly setup a weekly meal plan, and today we serve 190
								meals every day.”
								<Grid item></Grid>
							</h2>
						</Card>
					</Grid>
					<Grid item>
						<Card
							elevation={0}
							style={{
								width: 370,
								height: 370,
								borderRadius: 15,
								marginLeft: 100,
								backgroundColor: "ghostwhite",
							}}
						>
							<img
								src={meal}
								style={{
									height: 65,
									width: 70,
									borderRadius: 50,
									marginTop: 20,
									marginLeft: 30,
								}}
							></img>
							<h3
								style={{
									textAlign: "center",
									marginTop: -60,
									fontSize: 24,
									marginLeft: 10,
									fontWeight: "600",
								}}
							>
								Sachin Dule
							</h3>
							<h5
								style={{
									textAlign: "right",
									marginTop: -20,
									fontSize: 19.5,
									marginRight: 20,
									fontWeight: "normal",
									color: "black",
									fontStyle: "normal",
								}}
							>
								Director, The Meal Club LLC
							</h5>
							<h2
								style={{
									fontSize: 19.5,
									width: 320,
									fontWeight: "normal",
									fontFamily: "sans-serif",
									lineHeight: 2,
									marginLeft: 30,
								}}
							>
								“When EF approached us, I was skeptical about selling{" "}
								<span style={{ fontWeight: "bold" }}>our premium meal </span>on
								a subscription basis. But to my surprise, we started receiving
								orders from second day of going live, and we have a great
								journey so far!”
							</h2>
						</Card>
					</Grid>
					<Grid item>
						<Card
							elevation={0}
							style={{
								width: 370,
								height: 370,
								borderRadius: 15,
								marginLeft: 100,
								backgroundColor: "white",
							}}
						>
							<img
								src={dailymeal}
								style={{
									height: 65,
									width: 70,
									borderRadius: 50,
									marginTop: 20,
									marginLeft: 30,
								}}
							></img>
							<h3
								style={{
									textAlign: "center",
									marginTop: -60,
									fontSize: 24,
									marginLeft: 10,
									fontWeight: "600",
								}}
							>
								Chandan
							</h3>
							<h5
								style={{
									textAlign: "right",
									marginTop: -20,
									fontSize: 19.5,
									marginRight: 50,
									fontWeight: "normal",
									color: "black",
									fontStyle: "normal",
								}}
							>
								Owner, Daily Meals & <br></br>
								<span Tummy style={{ marginRight: 70 }}>
									Tummy Tales
								</span>
							</h5>
							<h2
								style={{
									fontSize: 19.5,
									width: 320,
									fontWeight: "normal",
									fontFamily: "sans-serif",
									lineHeight: 2,
									marginLeft: 30,
								}}
							>
								“We started with EF as an experiment. Our target customers were
								same. We have a good{" "}
								<span style={{ fontWeight: "bold" }}>
									{" "}
									inflow of corporate customers
								</span>
								, who are loyal. We have no hassle payments through EF. Kudos!”
								<Grid item></Grid>
							</h2>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Restaurant;
