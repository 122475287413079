import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Container,
	Fade,
	Grid,
	Paper,
	Stack,
	Typography,
	Zoom,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Budget from "../../assets/images/budget.png";
import Healthy from "../../assets/images/healthy.png";
import Web from "../../assets/images/web.png";
import "./HeroCaurosel.scss";
import { ArrowForward } from "@material-ui/icons";
import two from "../../assets/images/banners/2.png";
import three from "../../assets/images/banners/3.png";
import four from "../../assets/images/banners/4.png";
import five from "../../assets/images/banners/5.png";
import six from "../../assets/images/banners/6.png";
import seven from "../../assets/images/banners/7.png";
import e8 from "../../assets/images/banners/8.png";
import n9 from "../../assets/images/banners/9.png";
import ten from "../../assets/images/banners/10.png";
const Texts = [
	{
		id: 11,
		header: "Do not get Stuck with One Kitchen!",
		caption:
			"Did not like the meal? Switch to another curated kitchen anytime!. Never get stuck with one Kitchen!",
		img: Budget,
	},

	{
		id: 8,
		header: "Meal for all budgets!",
		caption:
			"Delicious food that's super affordable and all delivered healthy, hot, and fresh to your doorstep. ",
		img: Healthy,
	},

	{
		id: 3,
		header: "Choose Your Plan Duration",
		caption: "From Weekly to Monthly Meal Plans, efoodia has got you covered!",
		img: Budget,
	},

	{
		id: 5,
		header: "Discover 1000+ Meal Plans",
		caption:
			"Download our app today and discover a vast range of affordable and trustworthy meal providers",
		img: Budget,
	},

	{
		id: 7,
		header: "Healthify You!",
		caption: "Stay healthy, save money and time, and satisfy your cravings",
		img: Budget,
	},

	{
		id: 9,
		header: "Your health, is the only choice!",
		caption:
			"We believe eating healthy is one of the most important daily decisions one makes",
		img: Budget,
	},
];
const HeroBanners = (props) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % Texts.length);
		}, 10000);

		return () => clearInterval(interval);
	}, []);
	return (
		<Grid
			container
			spacing={2}
			sx={{
				alignItems: "center",
				justifyContent: "center",
				display: "flex",
				flexDirection: { md: "row", xs: "column" },
				marginBottom: "50px",
			}}
		>
			<Grid
				item
				xs={12}
				md={4}
				alignItems={"center"}
				justifyContent={"center"}
				display={"flex"}
				flexDirection={"column"}
			>
				<Fade
					in={true}
					timeout={{ enter: 300, exit: 30 }}
					style={{ transitionDelay: "300ms" }}
					key={currentIndex}
				>
					<Stack
						sx={{
							minHeight: "30vh",
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
						}}
					>
						<Typography
							style={{
								fontFamily: "Poppins",
								fontWeight: "bold",
								fontSize: "40px",
								color: "#444444",
								textAlign: "center",
							}}
						>
							{Texts[currentIndex].header}
						</Typography>
						<Typography
							style={{
								fontFamily: "Poppins",
								fontWeight: "400",
								fontSize: "24px",
								textAlign: "center",
							}}
						>
							{Texts[currentIndex].caption}
						</Typography>
					</Stack>
				</Fade>
				<Box
					sx={{
						alignItems: "center",
						justifyContent: "center",
						width: 160,
						height: 40,
						display: "flex",
						flexDirection: "row",

						mt: 1,
					}}
				>
					<Button
						style={{
							backgroundColor: "#53b022",
							color: "#ffffff",
							height: 40,
							borderRadius: 0,
							textTransform: "capitalize",
							width: "80%",
						}}
						onClick={() =>
							window.open(
								"https://apps.apple.com/in/app/encyclofoodia/id6456944295",
								"_blank"
							)
						}
					>
						Discover
					</Button>
					<Button
						style={{
							backgroundColor: "#3fa42b",
							color: "#ffffff",
							height: 40,
							borderRadius: 0,
						}}
						onClick={() =>
							window.open(
								"https://apps.apple.com/in/app/encyclofoodia/id6456944295",
								"_blank"
							)
						}
					>
						<ArrowForward style={{ fontSize: 18 }} />
					</Button>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				md={4}
				sx={{
					alignItems: "center",
					justifyContent: "flex-end",
					display: {
						md: "flex",
						xs: "none",
					},
				}}
			>
				<div className='gallery'>
					<img src={two} alt='a dream catcher' />
					<img src={three} alt='a piano' />
					<img src={four} alt='a live concert' />
					<img src={five} alt='Paris' />
					<img src={six} alt='A women in kayak' />
					<img src={seven} alt='many clothes and pictures' />
					<img src={e8} alt='someone preparing artisanal coffee' />
					<img src={n9} alt='some tee' />
					<img src={ten} alt='an empty table in a restaurant' />
				</div>
			</Grid>
		</Grid>
	);
};
export default HeroBanners;
