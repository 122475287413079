import "./Foodie.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../../theme";
import ButtonYellow from "../ButtonGreen/ButtonGreen";
import street_food from "../../../assets/images/street_food.png";
import { Box, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextBox from "../TextBox/TextBox";

function Foodie() {
  return (
    <ThemeProvider theme={theme}>
      <div className="home">
        <svg
          viewBox="0 0 624 613"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="right-blob"
        >
          <path
            d="M3.30097 25.3858L8.16279 -1H624V589.409L536.028 609.022C524.169 611.666 512.056 613 499.906 613H496.314C448.972 613 404.112 591.822 374.026 555.268C353.084 529.825 340.6 498.483 338.313 465.609L334.068 404.583C327.545 310.821 254.522 235.341 161.027 225.72L152.764 224.87C114.415 220.924 78.5539 204.033 51.0884 176.98C11.0063 137.499 -6.89396 80.715 3.30097 25.3858Z"
            fill="#FEBD2F"
          />
        </svg>
        {/* <img src={foodbowl_1} alt="Food Bowl" className="food-bowl-1"></img> */}
        <div className="foodie-full-page">
          <div className="foodie-text-box">
            Hey<span className="txt-yellow"> Foodie! </span>
          </div>
          <img
            src={street_food}
            alt="Street Food"
            className="street-food"
          ></img>
          <Box className="info-card1">
            <TextBox>
              Pay for what you buy. No hidden markup in the food pricing.
              Complete transparency guaranteed!
            </TextBox>
          </Box>
          <Box className="info-card2">
            <TextBox>
              Build a community around your love for food
            </TextBox>
          </Box>
          <Box className="info-card3">
            <TextBox>
              Find all the Best places to eat at one place and explore what
              adventure you want to embark on today
            </TextBox>
          </Box>
          <Box className="info-card4">
            <TextBox>
              Discover the best food experiences shared by other foodies around
              the world. And share your food adventures with like minded
              foodies.
            </TextBox>
          </Box>

          <div className="fbtn">
            <ButtonYellow onClick={() => window.open(
              "https://play.google.com/store/apps/details?id=com.encyclofoodia.shop",
              "_blank"
            )}>Download Now</ButtonYellow>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Foodie;
