import axios from 'axios';

// const config = {
//   G_API_KEY: 'AIzaSyDQyfh5-B07lQestVHBEOQpC253nuBWbqs',
//   BASE_URL: "https://api.staging.encyclofoodia.com",
//   BASIC_AUTH_TOKEN: "RGJicWtTdTJxUGMwV0tVdU9vS3FCa3BpOlFicGkxSFZOZkFkVmZUeHRMVzIwTkRRNXlWYTZQSW1vYkIzMVhwdVM4UVRxS05BbA==",
//   CONSUMER_KEY: 'ck_6012e0faf6adda677673a722af5de43f8880cdfe',
//   CONSUMER_SECRET: 'cs_0d4bbf9fc9b2082e6446981413272cc504815e64'
// };
// const config = {
//   G_API_KEY: 'AIzaSyDQyfh5-B07lQestVHBEOQpC253nuBWbqs',
//   BASE_URL: "http://localhost:5000/",
//   BASIC_AUTH_TOKEN: 'ZkVUNUp4cUFEODR3OW5DRHc4NWJONGw5OjJ3ZVVhRUVOZnZUb0w4R25DamVqZ2Q1dXZrRnBNYUl6ZXFGMG5IN0FQd1hYbXYxeA==',
//   CONSUMER_KEY: 'ck_6012e0faf6adda677673a722af5de43f8880cdfe',
//   CONSUMER_SECRET: 'cs_0d4bbf9fc9b2082e6446981413272cc504815e64'
// };


const config = {
  G_API_KEY: 'AIzaSyDQyfh5-B07lQestVHBEOQpC253nuBWbqs',
  BASE_URL: "https://api.encyclofoodia.com/",
  BASIC_AUTH_TOKEN: "OTV6eFNBMUJvQTBhZUp6REdCNE44VjJzOmhESmdaWG1rNDhOQ3RhWWNMek5DWHpWQlBXNVoyVnBVRGp2ejRPdzdPTkV5TEVCRA==",
  CONSUMER_KEY: "ck_6012e0faf6adda677673a722af5de43f8880cdfe",
  CONSUMER_SECRET: "cs_0d4bbf9fc9b2082e6446981413272cc504815e64"
};

const ck = config.CONSUMER_KEY;
const cs = config.CONSUMER_SECRET;

const api = axios.create({
  baseURL: config.BASE_URL,
  headers: {
    Authorization: 'Basic ' + config.BASIC_AUTH_TOKEN,
    'Content-Type': 'application/json',
    accept: '*/*'
  }
});

api.defaults.timeout = 25000;
export default api;
