import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import eflogo from "../../../../assets/images/logo_efoodia_black.webp";
import { TextField } from "@material-ui/core";
import india from "../../../../assets/images/india.png";
import uae from "../../../../assets/images/uae.png";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import DownloadApp from "../../AppIcons/DownloadApp";
const pages = [
	{ id: 1, title: "Home", link: "/" },
	{ id: 4, title: "Offers", link: "/offers" },
	{ id: 10, title: "Corporates", link: "/corporate" },
	{ id: 7, title: "Chefs", link: "/chef" },

	{ id: 5, title: "Corporates", link: "/corporate" },
	{
		id: 6,
		title: "Blogs",
		link: "https://blog.efoodia.com",
	},
	{
		id: 7,
		title: "Community",
		link: "https://www.skool.com/encyclofoodia-6020?invite=38f7bf6222ab4fd39da3f9279f4d4687",
	},
];
const styles = {};

function HeaderV2() {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const handleNavigation = (page) => {
		if (page.title === "Community" || page.title == "Blogs") {
			window.open(page.link, "_blank");
		} else {
			navigate(page.link);
		}
	};
	let navigate = useNavigate();
	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<AppBar position='static' sx={{ bgcolor: "transparent" }} elevation={0}>
			<Container maxWidth='xl'>
				<Toolbar disableGutters>
					<Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
						<img
							src={eflogo}
							alt={"Efoodia"}
							width={"15%"}
							height='15%'
							onClick={() => navigate("/")}
							style={{ cursor: "pointer" }}
						/>
					</Box>
					<Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
						<IconButton
							size='large'
							aria-label='account of current user'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={handleOpenNavMenu}
							// color='inherit'
							sx={{ color: "#444444" }}
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "flex", md: "none", flexDirection: "column" },
							}}
						>
							{pages.map((page) => (
								<MenuItem key={page.id}>
									<Link href={page.link}>
										<Typography
											textAlign='center'
											sx={{
												color: "#444444",
												fontWeight: "400",
												fontFamily: "Poppins",
											}}
										>
											{page.title}
										</Typography>
									</Link>
								</MenuItem>
							))}
						</Menu>
					</Box>

					<Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
						<img
							src={eflogo}
							alt={"Efoodia"}
							width={"50%"}
							height='50%'
							onClick={() => navigate("/")}
							style={{ cursor: "pointer" }}
						/>
					</Box>
					<Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
						{pages.map((page) => (
							<Button
								key={page.id}
								onClick={() => handleNavigation(page)}
								sx={{
									width: 120,
									my: 0,
									color: "#444444",
									display: "flex",
									fontWeight: "400",
									fontSize: 14,
									fontFamily: "Poppins",
								}}
							>
								{page.title}
							</Button>
						))}
					</Box>
					<Box sx={{ flexGrow: 0, mr: 1 }}>
						<DownloadApp size='small' />
					</Box>
					<Box sx={{ flexGrow: 0 }}>
						<TextField
							// label="Select country"
							select
							defaultValue={"UAE"}
							size={{ xs: "small", md: "medium", lg: "medium" }}
							style={{
								//border: "1px solid red",

								backgroundColor: "white",
								borderRadius: 5,
								fontWeight: "bold",
							}}
							variant='outlined'
						>
							<MenuItem value='IN' style={{ fontWeight: "bold", marginTop: 5 }}>
								<Avatar
									src={india}
									style={{
										width: 26,
										height: 26,
										marginTop: 0,
										// marginLeft: 2,
									}}
								></Avatar>
							</MenuItem>
							<MenuItem
								value='UAE'
								style={{ fontWeight: "bold", marginTop: 5 }}
							>
								<Avatar
									src={uae}
									style={{
										width: 25,
										height: 25,
										marginLeft: 0,
									}}
								></Avatar>
							</MenuItem>
						</TextField>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
export default HeaderV2;
