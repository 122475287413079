import api from './core';
import _ from 'lodash';
export default {
  getStore,
  productSearch,
  getStoreProducts,
  createCouponFromTemplate,
  getCouponTemplates,
  getCoupons
}

const token = 'GAN01AmLsjeoEFGTdUF2rAa4Uz4IE9hc0BatqwKu0m'

function getStore(storeId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/dcs/v1/stores/${storeId}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(result => {
        return resolve(result.data);
      })
      .catch(err => {
        return reject(err);
      });
  });
}
function productSearch(payload) {
  return new Promise((resolve, reject) => {
    api
      .get(`/dcs/v1/products/search`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        params: payload.data
      })
      .then(result => {
        return resolve(result.data.data);
      })
      .catch(err => {
        return reject(err);
      });
  });
}
function getStoreProducts(storeId, token) {
  return new Promise((resolve, reject) => {
    api
      .get(`/dcs/v1/stores/${storeId}/products`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(result => {
        resolve(result.data.data);
      })
      .catch(err => {
        return reject(err);
      });
  });
}
function getCouponTemplates(params, token) {
  return new Promise((resolve, reject) => {
    api
      .get(`/mkt/v1/coupon-templates`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        params,
      })
      .then(result => {
        resolve(result.data.data);
      })
      .catch(err => {
        return reject(err);
      });
  });
}
function createCouponFromTemplate(payload, couponTemplateId, token) {
  return new Promise((resolve, reject) => {
    api
      .post(`/mkt/v1/coupon-templates/${couponTemplateId}/coupons`, payload, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(result => {
        resolve(result.data.data);
      })
      .catch(err => {
        return reject(err);
      });
  });
}
function getCoupons(params, token) {
  return new Promise((resolve, reject) => {
    api
      .get(`/mkt/v1/coupons`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        params,
      })
      .then((result) => {
        const coupons = result.data.data;
        return resolve(coupons);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

