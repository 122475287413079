import web from "../../assets/images/web.png";
import g1 from "../../assets/images/minigirl.png";
import g2 from "../../assets/images/naziur.png";
import g3 from "../../assets/images/sharon.png";
const testimonials = [
	{
		id: 1,
		customerName: "Harsha Shenoy",
		designation: "Application Manager, Connect",
		title: "Value for Money!",
		testimonial:
			'"Their curated meal plans fit my budget, and the ability to pause my subscription when life gets hectic is a game-changer."',
		image: g1,
	},
	{
		id: 2,
		customerName: "Muhammed Naziur",
		designation: "UX Designer, Al Barsha",
		title: "Love its flexibility!",
		testimonial:
			'"I’ve been on the Encyclofoodia journey for a few months now, and it’s been incredible. I love the flexibility to switch between meal plans, customer support is top notch!"',
		image: g2,
	},
	{
		id: 3,
		customerName: "Sharon D'Susa",
		designation: "HR Manager, Business Bay",
		title: "Reliable & Supportive!",
		testimonial:
			"“Encyclofoodia has not only simplified my meal planning but also introduced me to a thriving community of food enthusiasts. The trust I have in their verified meal providers is unparalleled.“",
		image: g3,
	},
];
export default testimonials;
