import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const values = [
	{
		id: 1,
		title: "Lack of Choices",
		desc: "Most of the workplaces, either have a canteen with just one choice or no provision for availing meals for employees at all.",
	},
	{
		id: 2,
		title: "Expensive & inconvenient",
		desc: "Many companies partner with a catering service to run their canteen resulting into Lack of Quality Control, Operations overhead & Food wastage",
	},
	{
		id: 3,
		title: "Overheads & Wastage",
		desc: "Many companies partner with a catering service to run their canteen resulting into Lack of Quality Control, Operations overhead & Food wastage",
	},
	{
		id: 4,
		title: "Lack of Feedback Loops",
		desc: "There is no convenient way for corporate, the employees and the caterers to provide or recieve  feedback on feedback on food being served.",
	},
];

const ValueProposition = () => {
	return (
		<Box style={styles.root}>
			<Grid container spacing={4} style={styles.grid}>
				<Grid item xs={12} sm={4}>
					<Box style={styles.hero_image_container}>
						<img
							src='https://ik.imagekit.io/efoodia/Web/Untitled%20design%20(1).svg?updatedAt=1711891693053'
							style={styles.hero_image}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Stack>
						<Typography style={styles.hero_heading}>Why Care?</Typography>
						<Grid container spacing={4} direction='row'>
							{values.map((value) => {
								return (
									<Grid item xs={12} sm={6}>
										<Paper style={styles.container}>
											<Stack style={styles.paper}>
												<Stack direction={"row"} gap={1} style={styles.line}>
													<CheckCircleIcon style={styles.check} />
													<Typography style={styles.title}>
														{value.title}
													</Typography>
												</Stack>
												<Typography style={styles.desc}>
													{value.desc}
												</Typography>
											</Stack>
										</Paper>
									</Grid>
								);
							})}
						</Grid>
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};
export default ValueProposition;
const styles = {
	check: {
		fontSize: 18,
		color: "#57B368",
	},
	container: {
		boxShadow:
			"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
	},
	line: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	grid: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	paper: {
		padding: 10,
		alignItems: "flex-start",
		justifyContent: "center",
		display: "flex",
	},
	title: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		fontSize: "18px",
		color: "#444444",
	},
	desc: {
		fontFamily: "Poppins",
		fontWeight: "500",
		fontSize: "14px",
		color: "#000000",
	},
	root: {
		width: "100%",
		paddingRight: 10,
		minHeight: "40vh",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	hero_image_container: {
		maxWidth: "900px",
		maxHeight: "900px",
	},
	hero_image: {
		width: "100%",
		height: "100%",
		objectFit: "contain",
	},
	hero_heading: {
		fontFamily: "Poppins",
		fontWeight: "500",
		fontColor: "#444444",
		fontSize: 50,
	},
};
