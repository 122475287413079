import "./ContactUs.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../common/theme";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ContactUs() {

  return (
		<ThemeProvider theme={theme}>
			<br></br>
			<br></br>
			<div className='privacy-policy-container'>
				<Typography variant='h3' className='header'>
					Contact Information
				</Typography>
				<br />
				<br />
				<Typography variant='h4' className='textStyle'>
					Email : sales@encyclofoodia.com
				</Typography>
				<Typography variant='h4' className='textStyle'>
					Phone : +971-551253771
				</Typography>
			</div>
		</ThemeProvider>
	);
}

export default ContactUs;