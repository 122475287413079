import "./AboutUs.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../common/theme";
import { Typography } from "@mui/material";

function AboutUs() {
  return (
    <ThemeProvider theme={theme}>
      <div className="privacy-policy-container">
        <Typography variant="h3" className="header">
          About Us
        </Typography>
        <br />
        <br />
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", color: "limegreen" }}
        >
          Who are we?
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="h6" className="textStyle">
          Encyclofoodia is a Direct to Customer platform for foodies where they
          can discover, share and earn out of their love for food. In addition
          to reaping the benefits of a social platform, foodies can choose from
          a variety of local multicuisine restaurants and get food delivered
          directly from restaurants at no hidden charges.
          <br />
          <br />
          Encyclofoodia identifies and aggregates local businessess that are
          waiting to be discovered, but do not have a brand or marketing
          strategy to improve sales. Our platform enables them to take their
          business online where the food delivery industry thrives.
          Encyclofoodia shall co-brand, provide a channel for online food
          ordering, invest in marketing, technology and delivery operations for
          them, thus empowering local businesses to have a self-reliant in-house
          delivery system. <br /> <br />
          We do not charge exorbitant aggregator commissions or markups and
          enable restaurants to maximize profitability. In addition, through our
          platform, restaurants can connect with their customers ensuring
          constantly improving services and a content foodie community
        </Typography>
        <br></br>
        <br></br>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", color: "limegreen" }}
        >
          Why eFoodia?
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="h6" className="textStyle">
          efoodia is revolutionizing the meal planning experience by offering a
          platform with more than 100 trusted meal providers with curated daily,
          weekly, and monthly meal plans that fit your lifestyle and budget.
          <br></br>
          <br></br> Our app simplifies your meal planning with a customizable
          and affordable subscription service that allows separate subscriptions
          for all your meals. With our easy-to-use interface, you can switch
          between meal providers and broaden your culinary horizon. <br></br>
          <br></br> Our flexible delivery options allow you to schedule
          deliveries to multiple locations, including your home or workplace.{" "}
          <br></br>
          <br></br> We take the hassle out of meal planning so you can sit back,
          relax, and enjoy the convenience and flexibility that efoodia
          delivers. <br></br>
          <br></br> Join our community today and take the first step towards
          effortless and stress-free meal planning!
        </Typography>{" "}
      </div>
    </ThemeProvider>
  );
}

export default AboutUs;
