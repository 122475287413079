import "./PrivacyPolicy.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../common/theme";
import { Grid, Typography } from "@mui/material";

function PrivacyPolicy() {
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        className="privacy-policy-container"
        style={{
          marginTop: 180,
          backgroundColor: "white",
        }}
      >
        <h2
          style={{
            fontSize: 55,
            fontWeight: "bold",
            marginLeft: 370,
            marginTop: 100,
          }}
        >
          Privacy Policy
        </h2>
        <h5
          style={{
            fontSize: 21,
            fontWeight: "lighter",
            marginTop: 250,
            marginLeft: -500,
          }}
        >
          Privacy Policy of Encyclofoodia Private Limited
        </h5>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 150,
            width: 850,
            marginLeft: 180,
          }}
        >
          This Privacy Policy (“Policy”) describes the policies and procedures
          on the collection, use, disclosure and protection of your information
          when you use our website located at Encyclofoodia.com, or the
          Encyclofoodia mobile application (collectively, “Encyclofoodia
          Platform”) made available by Encyclofoodia Private Limited
          (“Encyclofoodia”, “Company”, “we”, “us” and “our”), a private company
          established under the laws of India having its registered office at
          E203, DS MAX Sprinkles, Kodathi Railwaygate, Bangalore 560035.
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          The terms “you” and “your” refer to the user of the Encyclofoodia
          Platform. The term “Services” refers to any services offered by
          Encyclofoodia whether on the Encyclofoodia Platform or otherwise.
          Please read this Policy before using the Encyclofoodia Platform or
          submitting any personal information to Encyclofoodia. This Policy is a
          part of and incorporated within, and is to be read along with, the
          Terms of Use.
        </Typography>
        {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <Typography
          style={{
            fontSize: 50,
            fontWeight: "bold",
            marginLeft: 180,
            marginTop: 50,
          }}
        >
          YOUR CONSENT
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          By using the Encyclofoodia Platform and the Services, you agree and
          consent to the collection, transfer, use, storage, disclosure and
          sharing of your information as described and collected by us in
          accordance with this Policy. If you do not agree with the Policy,
          please do not use or access the Encyclofoodia Platform.
        </Typography>
        <Typography
          style={{
            fontSize: 50,
            fontWeight: "bold",
            marginLeft: 180,
            marginTop: 50,
          }}
        >
          POLICY CHANGES
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          We may occasionally update this Policy and such changes will be posted
          on this page. If we make any significant changes to this Policy we
          will endeavour to provide you with reasonable notice of such changes,
          such as via prominent notice on the Encyclofoodia Platform or to your
          email address on record and where required by applicable law, we will
          obtain your consent. To the extent permitted under the applicable law,
          your continued use of our Services after we publish or send a notice
          about our changes to this Policy shall constitute your consent to the
          updated Policy.
        </Typography>
        <Typography
          style={{
            fontSize: 50,
            fontWeight: "600",
            marginLeft: 180,
            marginTop: 50,
          }}
        >
          LINKS TO OTHER WEBSITES
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          The Encyclofoodia Platform may contain links to other websites. Any
          personal information about you collected whilst visiting such websites
          is not governed by this Policy. Encyclofoodia shall not be responsible
          for and has no control over the practices and content of any website
          accessed using the links contained on the Encyclofoodia Platform. This
          Policy shall not apply to any information you may disclose to any of
          our service providers/service personnel which we do not require you to
          disclose to us or any of our service providers under this Policy.{" "}
        </Typography>
        <Typography
          style={{
            fontSize: 50,
            fontWeight: "600",
            marginLeft: 180,
            marginTop: 50,
          }}
        >
          INFORMATION WE COLLECT<br></br> FROM YOU
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          Information: In order to improve your app experience and lend
          stability to our services to you, we may collect information or employ
          third party plugins that collect information about the devices you use
          to access our Services, including the hardware models, operating
          systems and versions, software, file names and versions, preferred
          languages, unique device identifiers, advertising identifiers, serial
          numbers, device motion information, mobile network information,
          installed applications on device and phone state. The information
          collected thus will be disclosed to or collected directly by these
          plugins and may be used to improve the content and/or functionality of
          the services offered to you. Analytics companies may use mobile device
          IDs to track your usage of the Encyclofoodia Platform;
        </Typography>
        <Typography
          style={{
            fontSize: 50,
            fontWeight: "600",
            marginLeft: 180,
            marginTop: 50,
          }}
        >
          COOKIES
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          Our Encyclofoodia Platform and third parties with whom we partner, may
          use cookies, pixel tags, web beacons, mobile device IDs, “flash
          cookies” and similar files or technologies to collect and store
          information with respect to your use of the Services and third-party
          websites. <br></br>Cookies are small files that are stored on your
          browser or device by websites, apps, online media and advertisements.
          We use cookies and similar technologies for purposes such as:
        </Typography>
        <Typography
          style={{
            fontSize: 20,
            fontWeight: "normal",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          <ul>
            <li>
              Authenticating users; Remembering user preferences and settings;
            </li>
            <li>Determining the popularity of content;</li>
            <li>
              Delivering and measuring the effectiveness of advertising
              campaigns;
            </li>
            <li>
              Analysing site traffic and trends, and generally understanding the
              online behaviours and interests of people who interact with our
              services.
            </li>
          </ul>
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          A pixel tag (also called a web beacon or clear GIF) is a tiny graphic
          with a unique identifier, embedded invisibly on a webpage (or an
          online ad or email), and is used to count or track things like
          activity on a webpage or ad impressions or clicks, as well as to
          access cookies stored on user's computers. We use pixel tags to
          measure the popularity of our various pages, features and services. We
          also may include web beacons in e-mail messages or newsletters to
          determine whether the message has been opened and for other analytics.
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          To modify your cookie settings, please visit your browser’s settings.
          By using our Services with your browser settings to accept cookies,
          you are consenting to our use of cookies in the manner described in
          this section.
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          We may also allow third parties to provide audience measurement and
          analytics services for us, to serve advertisements on our behalf
          across the Internet, and to track and report on the performance of
          those advertisements. These entities may use cookies, web beacons,
          SDKs and other technologies to identify your device when you visit the
          Encyclofoodia Platform and use our Services, as well as when you visit
          other online sites and services.
        </Typography>
        <Typography
          style={{
            fontSize: 50,
            fontWeight: "600",
            marginLeft: 180,
            marginTop: 50,
          }}
        >
          USES OF YOUR INFORMATION
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          We use the information we collect for following purposes, including:
        </Typography>
        <Typography
          style={{
            fontSize: 20,
            fontWeight: "normal",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          <ol type="I">
            <li>
              To provide, personalise, maintain and improve our products and
              services, such as to enable deliveries and other services, enable
              features to personalise your Encyclofoodia account;
            </li>
            <li>
              To carry out our obligations arising from any contracts entered
              into between you and us and to provide you with the relevant
              information and services;
            </li>
            <li>
              To administer and enhance the security of our Encyclofoodia
              Platform and for internal operations, including troubleshooting,
              data analysis, testing, research, statistical and survey purposes;
            </li>
            <li>
              To provide you with information about services we consider similar
              to those that you are already using, or have enquired about, or
              may interest you. If you are a registered user, we will contact
              you by electronic means (e-mail or SMS or telephone) with
              information about these services;
            </li>
            <li>
              To understand our users (what they do on our Services, what
              features they like, how they use them, etc.), improve the content
              and features of our Services (such as by personalizing content to
              your interests), process and complete your transactions, make
              special offers, provide customer support, process and respond to
              your queries;
            </li>
            <li>
              To generate and review reports and data about, and to conduct
              research on, our user base and Service usage patterns;
            </li>
            <li>
              To allow you to participate in interactive features of our
              Services, if any; or
            </li>
            <li>
              To measure or understand the effectiveness of advertising we serve
              to you and others, and to deliver relevant advertising to you.
            </li>
            <li>
              If you are a partner restaurant or merchant or delivery partner,
              to track the progress of delivery or status of the order placed by
              our customers.
            </li>
            <li>to carry out academic research with academic partners.</li>
          </ol>
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          We may combine the information that we receive from third parties with
          the information you give to us and information we collect about you
          for the purposes set out above. Further, we may anonymize and/or
          de-identify information collected from you through the Services or via
          other means, including via the use of third-party web analytic tools.
          As a result, our use and disclosure of aggregated and/or de-identified
          information is not restricted by this Policy, and it may be used and
          disclosed to others without limitation.
          <br /> <br /> We analyse the log files of our Encyclofoodia Platform
          that may contain Internet Protocol (IP) addresses, browser type and
          language, Internet service provider (ISP), referring, app crashes,
          page viewed and exit websites and applications, operating system,
          date/time stamp, and clickstream data. This helps us to administer the
          website, to learn about user behavior on the site, to improve our
          product and services, and to gather demographic information about our
          user base as a whole.
        </Typography>
        <Typography
          style={{
            fontSize: 50,
            fontWeight: "600",
            marginLeft: 180,
            marginTop: 50,
          }}
        >
          DISCLOSURE AND DISTRIBUTION OF YOUR INFORMATION
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          We may share your information that we collect for following purposes:
        </Typography>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          {" "}
          • With Service Providers:
        </Typography>
        <Typography
          style={{
            fontSize: 22,
            fontWeight: "normal",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          We may share your information with our vendors, consultants, marketing
          partners, research firms and other service providers or business
          partners, such as Payment processing companies, to support our
          business. For example, your information may be shared with outside
          vendors to send you emails and messages or push notifications to your
          devices in relation to our Services, to help us analyze and improve
          the use of our Services, to process and collect payments. We also may
          use vendors for other projects, such as conducting surveys or
          organizing sweepstakes for us.
        </Typography>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          • With Partner Restaurants/Merchant:
        </Typography>
        <Typography
          style={{
            fontSize: 22,
            fontWeight: "normal",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          {" "}
          While you place a request to order food through the Encyclofoodia
          Platform, your information is provided to us and to the
          restaurants/merchants with whom you may choose to order. In order to
          facilitate your online food order processing, we provide your
          information to that restaurant/merchant in a similar manner as if you
          had made a food order directly with the restaurant. If you provide a
          mobile phone number, Encyclofoodia may send you text messages
          regarding the order's delivery status.
        </Typography>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          • With Academic Partners:
        </Typography>
        <Typography
          style={{
            fontSize: 22,
            fontWeight: "normal",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          {" "}
          We may share your information with our academic partners for the
          purpose of carrying out academic research.
        </Typography>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          • With Other Users:
        </Typography>
        <Typography
          style={{
            fontSize: 22,
            fontWeight: "normal",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          If you are a delivery partner, we may share your name, phone number
          and/or profile picture (if applicable), tracking details with other
          users to provide them the Services.
        </Typography>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          • For Crime Prevention or Investigation:
        </Typography>
        <Typography
          style={{
            fontSize: 22,
            fontWeight: "normal",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          {" "}
          We may share this information with governmental agencies or other
          companies assisting us, when we are: Obligated under the applicable
          laws or in good faith to respond to court orders and processes; or
          Detecting and preventing against actual or potential occurrence of
          identity theft, fraud, abuse of Services and other illegal acts;
          Responding to claims that an advertisement, posting or other content
          violates the intellectual property rights of a third party; Under a
          duty to disclose or share your personal data in order to enforce our
          Terms of Use and other agreements, policies or to protect the rights,
          property, or safety of the Company, our customers, or others, or in
          the event of a claim or dispute relating to your use of our Services.
          This includes exchanging information with other companies and
          organisations for the purposes of fraud detection and credit risk
          reduction.
        </Typography>
        <Typography
          style={{
            fontSize: 24,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          • For Internal Use:
        </Typography>
        <Typography
          style={{
            fontSize: 22,
            fontWeight: "normal",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          {" "}
          We may share your information with any present or future member of our
          “Group” (as defined below)or affiliates for our internal business
          purposes The term “Group” means, with respect to any person, any
          entity that is controlled by such person, or any entity that controls
          such person, or any entity that is under common control with such
          person, whether directly or indirectly, or, in the case of a natural
          person, any Relative (as such term is defined in the Companies Act,
          1956 and Companies Act, 2013 to the extent applicable) of such person.
        </Typography>
        <Typography
          style={{
            fontSize: 50,
            fontWeight: "600",
            marginLeft: 180,
            marginTop: 50,
          }}
        >
          DATA SECURITY PRECAUTIONS
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          We have in place appropriate technical and security measures to secure
          the information collected by us.
          <br />
          <br /> You are advised not to send your full credit/debit card details
          through unencrypted electronic platforms. Where we have given you (or
          where you have chosen) a username and password which enables you to
          access certain parts of the Encyclofoodia Platform, you are
          responsible for keeping these details confidential. We ask you not to
          share your password with anyone. <br /> <br />
          Please be aware that the transmission of information via the internet
          is not completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of your data
          transmitted through the Encyclofoodia Platform. Once we have received
          your information, we will use strict physical, electronic, and
          procedural safeguards to try to prevent unauthorised access.{" "}
        </Typography>
        <Typography
          style={{
            fontSize: 50,
            fontWeight: "600",
            marginLeft: 180,
            marginTop: 50,
          }}
        >
          GRIEVANCE OFFICER AND ENCYCLOFOODIA PLATFORM<br></br> SECURITY
        </Typography>
        <Typography
          style={{
            fontSize: 19,
            fontWeight: "600",
            marginTop: 30,
            width: 850,
            marginLeft: 180,
          }}
        >
          If you have any queries relating to the processing or usage of
          information provided by you in connection with this Policy, please
          email us at support@encyclofoodia.com If you come across any abuse or
          violation of the Policy, please report to support@encyclofoodia.in
          Further, please note that the Encyclofoodia Platform stores your data
          with the cloud platform of Google Cloud Platform, provided by Google
          Inc., which may store this data on their servers located outside of
          India. GCP has security measures in place to protect the loss, misuse
          and alteration of the information. In the event you have questions or
          concerns about the security measures adopted by GCP, you can contact
          their data protection / privacy team / legal team or designated the
          grievance officer at these organisations, whose contact details are
          available in its privacy policy, or can also write to our Grievance
          Officer at the address provided above.
        </Typography>
      </Grid>
    </ThemeProvider>
  );
}

export default PrivacyPolicy;
