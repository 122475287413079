import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import theme, { colors } from '../../theme';
const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: colors.green,
  fontSize: "1em",
  fontFamily: "Nunito",
  fontWeight: 800,
  textTransform: "none",
  minWidth: 220

}));

export default function ButtonGreen(props) {
  return (
    <ColorButton className={props.className} sx={props.sx} disabled={props.disabled} onClick={props.onClick} startIcon={props.startIcon} variant="contained" theme={theme}>{String(props.children)}</ColorButton>
  );
}
