import { Box, Container, Divider, Typography } from "@material-ui/core";
import React from "react";
import Hero from "../components/Hero";
import HeroResearch from "../components/HeroResarch";
import ClientList from "../components/Clients";
import ValueProposition from "../components/ValueProposition";
import Products from "../components/Products";
import Connect from "../components/Connect";
import ExecutiveMeals from "../../../common/components/ExecutiveMeals/ExecutiveMeals";
import { Stack } from "@mui/material";

const CorporateLandingPage = (props) => {
	const handleScroll = () => {
		let target = document.getElementById("connect-container");
		var scrollContainer = target;
		do {
			//find scroll container
			scrollContainer = scrollContainer.parentNode;
			if (!scrollContainer) return;
			scrollContainer.scrollTop += 1;
		} while (scrollContainer.scrollTop == 0);

		var targetY = 0;
		do {
			//find the top of target relatively to the container
			if (target == scrollContainer) break;
			targetY += target.offsetTop;
		} while ((target = target.offsetParent));

		const scroll = (c, a, b, i) => {
			i++;
			if (i > 30) return;
			c.scrollTop = a + ((b - a) / 30) * i;
			setTimeout(function () {
				scroll(c, a, b, i);
			}, 20);
		};
		// start scrolling
		scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
	};
	return (
		<Stack style={styles.root} gap={5}>
			<Hero onClick={handleScroll} />
			<Container>
				<ValueProposition />
			</Container>

			{/* <ClientList /> */}

			<Products />
			<HeroResearch />
			<Container>
				<Divider style={styles.divider} />
			</Container>
			<Container>
				<ExecutiveMeals />
			</Container>
			<div id='connect-container'>
				<Connect />
			</div>
		</Stack>
	);
};
const styles = {
	root: {
		width: "100%",
		padding: 10,
		alignItems: "center",
		justifyContent: "flex-start",
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
	},
	divider: {
		width: "100%",
		height: 2,
		// border: "1px solid",
		backgroundColor: "#f5f5f5",
	},
};

export default CorporateLandingPage;
