import * as React from 'react';
import { makeStyles } from "@material-ui/core/styles"
import { Box } from '@mui/material';

const useStyles = makeStyles({
    box: {
        padding: '2.5vh',
        boxShadow: '2px 4px 25px 0px rgba(232, 232, 232, 0.7)',
        display: 'block',
        textAlign: 'center',
        borderRadius: '1em',
        backgroundColor: 'white',
        fontFamily: "Open Sans",
        fontWeight: 400,
        lineHeight: "26px",
        fontSize: '1em',
        color: 'rgb(63, 50, 50,1)',
        margin: '1rem',
        marginTop: "-1rem"
    }
})

export default function TextBox(props) {
    const classes = useStyles(props);
    return (
        <Box className={classes.box}>{String(props.children)}</Box>
    )
}
