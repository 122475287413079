import React from "react";
import Offers from "../common/components/Offers/Offers";
import { Box, Container, Typography } from "@material-ui/core";
import CTAApp from "../common/components/CTASection/CTAApp";
import { Stack } from "@mui/material";

const RegistrationSuccess = (props) => {
	return (
		<Box
			style={{ width: "100%", backgroundColor: "#fafafa", minHeight: "100vh" }}
		>
			<Container
				fixed
				sx={{
					minHeight: "80vh",
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					marginBottom: 20,
					flexDirection: "column",
				}}
				className='dot'
			>
				<Stack gap={2} alignItems={"center"} justifyContent={"center"}>
					<Typography
						variant='h4'
						style={{ fontFamily: "Poppins", fontWeight: "700", marginTop: 10 }}
					>
						Thank You for Showing Interest
					</Typography>
					<Typography
						variant='h5'
						style={{
							fontFamily: "Poppins",
							fontWeight: "400",
							marginTop: 10,
							textAlign: "center",
						}}
					>
						Our representative shall connect with you soon! Till then, consider
						downloading EFoodia App and explore our meal plans!
					</Typography>
					<CTAApp />
				</Stack>
			</Container>
			<Container
				fixed
				sx={{
					minHeight: "60vh",
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					marginBottom: 30,
					marginTop: 40,
				}}
				className='dot'
			>
				<Typography
					variant='h4'
					style={{ fontFamily: "Poppins", fontWeight: "700", marginTop: 10 }}
				>
					Available Offers
				</Typography>
				<Offers />
			</Container>
		</Box>
	);
};
export default RegistrationSuccess;
