import {
  Box,
  Paper,
  TextField,
  Typography,
  Grid,
  Button,
  Icon,
  IconButton,
} from "@material-ui/core";
import { Alert, AlertTitle, Link, Snackbar, Stack } from "@mui/material";
import React, { useState } from "react";
import register_to_community from "../../../../api/community";
import india from "../../../../assets/images/india.png";
import uae from "../../../../assets/images/uae.png";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";
import google_play from "../../../../assets/images/google_play.png";
import appstore from "../../../../assets/images/appstore.png";
import {
  Facebook,
  Instagram,
  Twitter,
  YouTube,
  LinkedIn,
} from "@material-ui/icons";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
const customercare = [
  { id: 2, title: "Privacy Policy", link: "/privacy-policy" },
  { id: 3, title: "Terms of Use", link: "/terms-and-conditions" },
  {
    id: 5,
    title: "Customer Care",
    link: "/contact",
  },
  { id: 5, title: "Cancellation & Refunds", link: "/cancellation-and-refund" },
];

const encyclofoodia = [
  { id: 1, title: "About Us", link: "/about" },
  { id: 2, title: "Contact Us", link: "/contact" },

  {
    id: 4,
    title: "Community",
    link: "https://www.skool.com/encyclofoodia-6020",
  },
  { id: 3, title: "Remove My Data", link: "/deleteme" },
];
const validateEmail = (mail) => {
  return String(mail)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const FooterV2 = (props) => {
  const [email, setEmail] = useState(undefined);
  const [error, setError] = useState(null);
  const [register_success, setRegisterSuccess] = useState(false);
  const [register_error, setRegisterError] = useState("");
  const handleJoinCommunity = () => {
    setRegisterSuccess(false);
    setRegisterError("");

    const match = validateEmail(email);
    if (match) {
      register_to_community(email)
        .then((res) => {
          setRegisterSuccess(true);
        })
        .finally(() => {
          setRegisterSuccess(true);
        });
    } else {
      setError("Invalid Email Address");
    }
  };
  const handleChange = (e) => {
    setError(undefined);
    setEmail(e.target.value);
  };
  return (
    <Box
      sx={{
        display: "flex",
        p: 5,
        bgcolor: "#000000",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        //border: "1px solid red",
      }}
    >
      <Grid
        container
        direction="row"
        style={{ marginRight: 3, marginBottom: 9 }}
      >
        <Grid item style={{ flex: 2 }}>
          <Typography
            variant="h5"
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: 30,
            }}
          >
            Encyclofoodia
          </Typography>
        </Grid>
        <Grid item>
          <Box>
            <TextField
              select
              defaultValue={"UAE"}
              size={{ xs: "small", md: "medium", lg: "medium" }}
              style={{
                backgroundColor: "white",
                borderRadius: 5,
                fontWeight: "bold",
                width: 110,
                marginRight: 25,
              }}
              variant="outlined"
            >
              <MenuItem
                value="IN"
                style={{
                  fontWeight: "bold",
                  //border: "1px solid red",
                }}
              >
                <Avatar
                  src={india}
                  style={{
                    width: 26,
                    height: 26,
                    marginTop: 0,
                    // marginLeft: 2,
                  }}
                ></Avatar>
                <Typography style={{ marginLeft: 28, marginTop: -25 }}>
                  India
                </Typography>
              </MenuItem>
              <MenuItem value="UAE" style={{ fontWeight: "bold" }}>
                <Avatar
                  src={uae}
                  style={{
                    width: 25,
                    height: 25,
                    marginLeft: 0,
                  }}
                ></Avatar>
                <Typography style={{ marginLeft: 28, marginTop: -25 }}>
                  UAE
                </Typography>
              </MenuItem>
            </TextField>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex">
            <TextField
              select
              defaultValue={"English"}
              size={{ xs: "small", md: "medium", lg: "medium" }}
              style={{
                backgroundColor: "white",
                borderRadius: 5,
                fontWeight: "bold",
                width: 125,
                marginRight: 55,
              }}
              variant="outlined"
            >
              <MenuItem value="English" style={{ fontWeight: "bold" }}>
                <IconButton aria-label="Lang" style={{ width: 10, height: 10 }}>
                  <LanguageIcon />
                </IconButton>
                <Typography style={{ marginLeft: 28, marginTop: -23 }}>
                  English
                </Typography>
              </MenuItem>
              <MenuItem value="Italian">Italian</MenuItem>
              <MenuItem value="French">French</MenuItem>
              <MenuItem value="Polish">Polish</MenuItem>
            </TextField>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row" style={{ marginTop: 15 }}>
        <Grid item md={3} xs={12}>
          <Stack>
            <Typography
              style={{
                fontWeight: "bold",
                marginBottom: 3,
                color: "#ffffff",
              }}
            >
              Customer Care
            </Typography>
            {customercare.map((cc) => {
              return (
                <Link style={{ cursor: "pointer" }} href={cc.link}>
                  <Typography style={{ fontSize: 14, color: "#ffffff" }}>
                    {cc.title}
                  </Typography>
                </Link>
              );
            })}
          </Stack>
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography
            style={{ fontWeight: "bold", marginBottom: 3, color: "#ffffff" }}
          >
            About Encyclofoodia
          </Typography>
          <Stack>
            {encyclofoodia.map((ef) => {
              return (
                <div>
                  <Link
                    style={{ fontSize: 14, color: "#ffffff" }}
                    href={ef.link}
                  >
                    {ef.title}
                  </Link>
                </div>
              );
            })}
          </Stack>
        </Grid>
        <Grid item md={2} xs={12}>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              variant="h3"
              style={{ fontWeight: "bold", fontSize: 18, color: "white" }}
            >
              Contact Us
            </Typography>
            <Typography
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: 13,
                color: "white",
              }}
            >
              Email : info@encyclofoodia.com
            </Typography>
            <Typography
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: 13,
                color: "white",
              }}
            >
              Phone : +971-55 125 3771
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              variant="h3"
              style={{ fontWeight: "bold", fontSize: 18, color: "white" }}
            >
              Social Links
            </Typography>
            <Box
              direction="row"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                // border: "1px solid red",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                aria-label="Lang"
                style={{
                  width: 10,
                  height: 10,
                  color: "white",
                }}
              >
                <LinkedIn />
              </IconButton>
              <IconButton
                aria-label="Lang"
                style={{ width: 10, height: 10, color: "white" }}
              >
                <Instagram />
              </IconButton>
              <IconButton
                aria-label="Lang"
                style={{ width: 10, height: 10, color: "white" }}
              >
                <Twitter />
              </IconButton>
              <IconButton
                aria-label="Lang"
                style={{ width: 10, height: 10, color: "white" }}
              >
                <YouTube />
              </IconButton>
              <IconButton
                aria-label="Lang"
                style={{ width: 10, height: 10, color: "white" }}
              >
                <Facebook />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                //border: "1px solid red",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 3,
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                src={google_play}
                width={140}
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.encyclofoodia.shop",
                    "_blank"
                  )
                }
              />

              <img
                src={appstore}
                width={120}
                style={{ cursor: "pointer", marginTop: 3 }}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/in/app/encyclofoodia/id6456944295",
                    "_blank"
                  )
                }
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Typography
        style={{
          color: "#ffffff",
          fontFamily: "Poppins",
          fontSize: "12px",
          marginTop: 25,
        }}
      >
        (c) 2018-2024 Encyclofoodia LLC
      </Typography>
      <Snackbar
        open={register_error}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => setRegisterError("")}
      >
        <Alert severity="error" onClose={() => setRegisterError("")}>
          <AlertTitle>Error</AlertTitle>
          {register_error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={register_success}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => setRegisterSuccess(false)}
      >
        <Alert severity="success">
          <AlertTitle>Welcome to Encyclofoodia!</AlertTitle>
          You have successfully registered! We have sent an invite to your
          email!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FooterV2;
