import "./CancellationAndRefundPolicy.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../common/theme";
import { Typography } from "@mui/material";

function CancellationAndRefundPolicy() {
  return (
    <ThemeProvider theme={theme}>
      <div className="privacy-policy-container">
        <Typography variant="h3" className="header"> Cancellation and Refund Policy </Typography>
        <br />
        <br />
        <Typography variant="h6" className="textStyle">
          Any Capitalized terms used but not defined herein shall have the
          meaning assigned to them under the Terms of Use which govern your use
          of our website www.Encyclofoodia.com (the “Website”) and our
          ‘Encyclofoodia’ application for mobile and handheld devices (the
          “App”). The Website and the App are jointly referred to as the
          “Platform”.
          <br />
          <br />
          <br />
          <Typography variant="h4" className="textStyle">Customer Cancellation</Typography>
          <br />
          As a general rule Buyer shall not be entitled to cancel Order once
          placed. Buyer may choose to cancel Order only within one-minute of the
          Order being placed. However, subject to Buyer’s previous cancellation
          history, Encyclofoodia reserves the right to deny any refund to Buyer
          pursuant to a cancellation initiated by Buyer even if the same is
          within one-minute followed by suspension of account, as may be
          necessary in the sole discretion of Encyclofoodia.
          <br />
          If Buyer cancels his/her Order after one minute of placing it,
          Encyclofoodia shall have a right to collect a penalty of 100% of the
          Order amount for breach of contract terms as a compensation for the
          damages suffered by Encyclofoodia, with a right to either not to
          refund the Order value in case Buyer’s Order is prepaid or recover
          from the Buyer’s subsequent Order in case his/her Order is postpaid,
          to compensate the Merchants and PDPs.
          <br />
          <br />
          <Typography variant="h4" className="textStyle">Non-Customer Cancellation</Typography>
          <br />
          Encyclofoodia reserves the right to collect a penalty for the Orders
          constrained to be cancelled by Encyclofoodia for reasons not
          attributable to Encyclofoodia, including but not limited to:
          <ul>
            <li>
              in the event if the address provided by Buyer is either wrong or
              falls outside the delivery zone;
            </li>
            <li>
              failure to contact Buyer by phone or email at the time of
              delivering the Order booking;
            </li>
            <li>
              failure to deliver Buyer Order due to lack of information,
              direction or authorization from Buyer at the time of delivery; or
            </li>
            <li>
              unavailability of all the items ordered by Buyer at the time of
              booking the Order; or
            </li>
            <li>
              unavailability of all the items ordered by Buyer at the time of
              booking the Order. However, in the unlikely event of an item in an
              Order being unavailable, Encyclofoodia will contact the Buyer on
              the phone number provided to us at the time of placing the Order
              and inform Buyer of such unavailability. In such an event Buyer
              will be entitled to cancel the entire Order and shall be entitled
              to a refund to an amount upto 100% of the Order value.
            </li>
          </ul>
          In case of cancellations for the reasons attributable to Encyclofoodia
          or the Merchant, Encyclofoodia shall not collect any penalty from the
          Buyer.
          <br />
          <br />
          <br />
          <Typography variant="h4" className="textStyle">Refunds</Typography>
          <br />
          Buyer may be entitled to a refund for prepaid Orders. Encyclofoodia
          retains the right to retain the penalty payable by the Buyer, from the
          amount refundable to him/her. The Buyer shall also be entitled to a
          refund of proportionate value in the event packaging of an item in an
          Order or the complete Order, is either tampered or damaged and the
          Buyer refuses to accept at the time of delivery for the said reason;
          <br />
          <br /> Buyer may be entitled to a refund upto 100% of the Order value
          if vendor fails to deliver the Order due to a cause attributable to
          either vendor or Encyclofoodia, however such refunds will be assessed
          on a case to case basis by Encyclofoodia. <br />
          <br /> Our decision on refunds shall be final and binding.
          <br />
          <br /> All refund amounts shall be credited to Buyer’s account as may
          be stipulated as per the payment mechanism of Buyer’s choice, the
          estimated timelines are detailed as below, in case Buyer don't choose
          to credit it to Buyer’s wallet with his/her Encyclofoodia Account;{" "}
          <br />
          <br />
          In case of payment at the time of delivery, Buyer will not be required
          to pay for: <br />
          Orders where packaging is either tampered or damaged at the time of
          delivery; <br />
          Wrong Order being delivered;
          <br /> or Items missing from Buyer’s Order at the time of delivery.{" "}
          <br />
          Provided the same is communicated to Encyclofoodia Customer Care
          through the Platform before the Order is marked delivered.
        </Typography>
      </div>
    </ThemeProvider>
  );
}

export default CancellationAndRefundPolicy;
