import React from "react";
import testimonials from "../../../home/v2/TestimonialsData";
import {
	Avatar,
	Card,
	Container,
	Fade,
	Grid,
	Paper,
	Rating,
	Stack,
	Typography,
	Zoom,
} from "@mui/material";
import { FormatQuoteOutlined } from "@material-ui/icons";
import { Carousel } from "react-responsive-carousel";
const styles = {
	title: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		textTransform: "uppercase",
		fontSize: "22px",
		color: "#53B022",
		marginTop: 10,
	},
	name: {
		fontFamily: "Poppins",
		fontWeight: "600",
		fontSize: "14px",
		color: "#53B022",
		alignSelf: "center",
	},
	designation: {
		fontFamily: "Poppins",
		fontWeight: "500",
		fontSize: "14px",
		color: "#444444",
		alignSelf: "center",
	},
	testimonial: {
		fontFamily: "Poppins",
		fontWeight: "500",
		fontSize: "12px",
		color: "#444444",
		alignSelf: "center",
		textAlign: "center",
		marginTop: 10,
	},
};
const TestimonialV2 = (props) => {
	return (
		<div>
			<Grid container spacing={4}>
				{testimonials.map((test, index) => {
					return (
						<Grid item xs={12} md={4}>
							<Paper
								key={index}
								style={{
									width: "100%",
									alignSelf: "center",
									padding: 15,
									backgroundColor: "#fafafa",
									minHeight: "50vh",
									borderRadius: 10,
								}}
							>
								<Card
									elevation={0}
									sx={{
										backgroundColor: "#fafafa",
										// border: "1px solid #444444",
										p: 1,
									}}
								>
									<Grid
										container
										spacing={2}
										sx={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
										}}
									>
										<Grid item xs={12} md={12}>
											<Stack
												sx={{
													alignItems: "center",
													justifyContent: "center",
													display: "flex",
													flexDirection: "column",
												}}
											>
												<Avatar
													src={test.image}
													sx={{ width: 120, height: 120, marginBottom: 1 }}
												/>
												<FormatQuoteOutlined
													style={{
														color: "#10f0cc",
														fontSize: "36px",
														alignSelf: "center",
													}}
												/>
												<Typography style={styles.name}>
													{test.customerName} -{" "}
												</Typography>
												<Typography style={styles.name}>
													<span style={styles.designation}>
														{test.designation}
													</span>
												</Typography>
												<Rating
													name='rating'
													value={5}
													precision={0.5}
													readOnly
													style={{ marginLeft: 20 }}
												/>

												<Typography style={styles.title}>
													{test.title}
												</Typography>
											</Stack>
										</Grid>
										<Grid item xs={12} md={12}>
											<Container fixed>
												<Typography style={styles.testimonial}>
													{test.testimonial}
												</Typography>
											</Container>
										</Grid>
									</Grid>
								</Card>
							</Paper>
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
};
export default TestimonialV2;
