import axios from "axios";
const config = {
	G_API_KEY: "AIzaSyDQyfh5-B07lQestVHBEOQpC253nuBWbqs",
	BASE_URL: "https://api.hubapi.com/",
	BASIC_AUTH_TOKEN: "pat-eu1-384ad353-ac26-4035-a5d7-49ea793266de",
};

const marketing = axios.create({
	baseURL: config.BASE_URL,
	headers: {
		Authorization: "Bearer " + config.BASIC_AUTH_TOKEN,
		"Content-Type": "application/json",
		accept: "*/*",
	},
});

marketing.defaults.timeout = 25000;
export default marketing;
