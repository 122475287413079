import { Container, Typography } from "@material-ui/core";
import React from "react";
import Hero from "../components/Hero";
import { Stack } from "@mui/material";
import Features from "../components/Features";
const ChefHome = (props) => {
	return (
		<Stack gap={5}>
			<Hero />
			<Features />
		</Stack>
	);
};
export default ChefHome;
