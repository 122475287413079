import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { Button, Stack, Tooltip, Typography } from "@mui/material";

export default function ExecutiveMeals() {
	return (
		<ImageList sx={{ width: "100%", height: "100%" }}>
			<ImageListItem key='Subheader' cols={2}>
				<ListSubheader component='div'>
					<Typography
						style={{ fontFamily: "Poppins", fontWeight: "bold", fontSize: 24 }}
					>
						Our Subscription Plans
					</Typography>
				</ListSubheader>
			</ImageListItem>
			{itemData.map((item) => (
				<ImageListItem key={item.img}>
					<img
						srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
						src={`${item.img}?w=248&fit=crop&auto=format`}
						alt={item.title}
						loading='lazy'
					/>
					<ImageListItemBar
						title={item.title}
						subtitle={item.author}
						actionIcon={
							<Stack
								alignItems={"center"}
								direction={"row"}
								sx={{ display: { xs: "none", md: "flex" } }}
							>
								<Tooltip title={item.info}>
									<IconButton
										sx={{ color: "#ffffff" }}
										aria-label={`info about ${item.title}`}
									>
										<InfoIcon style={{ color: "#ffffff" }} />
									</IconButton>
								</Tooltip>

								{/* <Button
									style={{
										color: "#ffffff",
										backgroundColor: "#444444",
										marginRight: 5,
									}}
									onClick={() =>
										window.open(
											"https://apps.apple.com/in/app/encyclofoodia/id6456944295",
											"_blank"
										)
									}
								>
									Get App
								</Button> */}
							</Stack>
						}
					/>
				</ImageListItem>
			))}
		</ImageList>
	);
}

const itemData = [
	// {
	//   img: "https://ik.imagekit.io/efoodia/Web/2.svg?updatedAt=1705122397059",
	//   title: "Weight loss/Muscle Gain Plans",
	//   alt: "Weight loss/Muscle Gain Plans",
	//   author: "Starts at AED 18/Meal",
	//   rows: 2,
	//   cols: 2,
	//   info: "Fueling your journey to wellness, one wholesome plate at a time. Discover the power of balanced and personalized dietary plans – because great health begins with mindful choices.",
	//   featured: true,
	// },
	{
		img: "https://ik.imagekit.io/efoodia/Web/South.png?updatedAt=1705121619449",
		title: "South Indian Meal Box",
		alt: "South Indian Meal Box",
		author: "Starts at AED 60/Week",
		info: "Enjoy flavour of south, kerala & chettinad style food from curated kitchens. Lunch and Dinner Available",
		rows: 2,
		cols: 2,
		featured: true,
	},
	{
		img: "https://ik.imagekit.io/efoodia/Web/North.png?updatedAt=1705121619486",
		title: "North Indian Meal Box",
		alt: "North Indian Meal Box",
		author: "Starts at AED 60/Week",
		info: "Savor the rich tapestry of flavors in every bite – North Indian cuisine, where aromatic spices and vibrant colors come together to create a culinary masterpiece.",
	},
	{
		img: "https://ik.imagekit.io/efoodia/Web/Pak.png?updatedAt=1705121619521",
		title: "Pakistani Meal Box",
		alt: "Pakistani Meal Box",
		author: "Starts at AED 12/Meal",
		info: "Embark on a spicy gastronomic journey with Pakistani delights – where bold flavors and tantalizing spices dance on your taste buds, leaving a symphony of deliciousness in every mouthful.",
	},
	{
		img: "https://ik.imagekit.io/efoodia/Web/Srilankan.png?updatedAt=1705121619452",
		title: "Srilankan Delight",
		alt: "Srilankan Delight",
		author: "Starts at AED 15/Meal",
		info: "Indulge in the exotic allure of Sri Lankan delights – a symphony of spices, fresh ingredients, and vibrant flavors that transport your palate to the heart of this tropical paradise.",
		cols: 2,
	},
	{
		img: "https://ik.imagekit.io/efoodia/Web/Arabic.png?updatedAt=1705121619492",
		title: "Arabian Taste Meal Box",
		alt: "Arabian Taste Meal Box",
		author: "AED 150/Week",
		info: "Savor the magic of Arabian nights through the rich and aromatic tapestry of flavors in every dish – where tradition meets innovation, and each bite tells a story of culinary excellence.",
		cols: 2,
	},
];
