import React from "react";
import Offers from "../common/components/Offers/Offers";
import { Box, Container, Typography } from "@material-ui/core";

const OffersPage = (props) => {
	return (
		<Box
			style={{
				width: "100%",
				backgroundColor: "#fafafa",

				paddingTop: 10,
			}}
		>
			<Container
				fixed
				sx={{
					minHeight: "10vh",
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					marginBottom: 20,
					marginTop: 30,
				}}
				className='dot'
			>
				<Typography
					variant='h3'
					style={{ fontFamily: "Poppins", fontWeight: "700" }}
				>
					Available Offers
				</Typography>
			</Container>
			<Container fixed>
				<Offers />
			</Container>
		</Box>
	);
};
export default OffersPage;
