import "./Partner.css";
import { ThemeProvider } from "@emotion/react";
import theme from "../../theme";
import ButtonYellow from "../ButtonGreen/ButtonGreen";
import partners from "../../../assets/images/partners.png";
import { Box, Card, } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextBox from "../TextBox/TextBox";
import { useNavigate } from "react-router-dom";

function Partner() {
  let navigate = useNavigate()
  return (
    <ThemeProvider theme={theme}>
      <div className="home">
        <svg
          viewBox="0 0 624 613"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="right-blob"
        >
          <path
            d="M3.30097 25.3858L8.16279 -1H624V589.409L536.028 609.022C524.169 611.666 512.056 613 499.906 613H496.314C448.972 613 404.112 591.822 374.026 555.268C353.084 529.825 340.6 498.483 338.313 465.609L334.068 404.583C327.545 310.821 254.522 235.341 161.027 225.72L152.764 224.87C114.415 220.924 78.5539 204.033 51.0884 176.98C11.0063 137.499 -6.89396 80.715 3.30097 25.3858Z"
            fill="#FEBD2F"
          />
        </svg>
        <div className="part-full-page">
          <div className="part-text-box">
            Hey<span className="txt-yellow"> Partner! </span>
          </div>
          <img
            src={partners}
            alt="Partners"
            className="partners"
          ></img>
          <Box className="part-card1">
            <TextBox>
              Partner with us at a location you know the best and help grow the business with us.
            </TextBox>
          </Box>
          <Box className="part-card2">
            <TextBox>
              Day one experience of being an entrepreneur and expect great ROI on your investment for day one.
            </TextBox>
          </Box>

          <div className="btn">
            <ButtonYellow onClick={() => navigate('/contact')}>Contact us</ButtonYellow>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Partner;
