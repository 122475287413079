import React from "react";
import "./floating-button.css";
import { Avatar, Button, Fab, Typography } from "@mui/material";
import { Favorite } from "@material-ui/icons";
import logo from "../../../assets/images/logo_small.png";
import SupportIcon from "@mui/icons-material/Support";
const styles = {
	button: {
		backgroundColor: "#000000",
		color: "#444444",

		"&:hover": {
			backgroundColor: "#000000",
			color: "#ffffff",
		},
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	text: {
		fontSize: "14px",
		fontFamily: "Arial",
		color: "#ffffff",
		textTransform: "capitalize",
		marginLeft: "4px",
	},
	icon: {
		color: "#ffffff",
	},
};
const FloatingButton = (props) => {
	return (
		<div class='floating-button-div'>
			<Fab sx={styles.button} onClick={props.onClick} variant='extended'>
				<SupportIcon sx={styles.icon} />
				<Typography sx={styles.text}>Help!</Typography>
			</Fab>
		</div>
	);
};
export default FloatingButton;
