import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";

export const ThankYouText = ({ couponCode }) => {
  useEffect(() => {
    console.log(window);
    window.gtag("event", "conversion", {
      send_to: "AW-11146953421/Yu33CNrtgpgYEM2FpMMp",
    });
  }, []);
  return (
    <Grid container className="green-title-box">
      <Typography
        className="logged-in"
        sx={{ marginBottom: "1em", alignSelf: "center", width: "100%" }}
      >
        Thank you for showing interest! What's next?
      </Typography>
      <Typography></Typography>
      <Typography className="green-input-title">
        * We are launching on 1st May. Download the app from app/play store.
      </Typography>
      <Typography className="green-input-title">
        * Just login using the same phone number you used to register.
      </Typography>
      <Typography className="green-input-title">
        * Subscribe to a meal plan and apply the coupon:{" "}
        <Typography display={"inline"} className="logged-in">
          {couponCode} (Coupon Name)
        </Typography>{" "}
        to avail 10% additional discount
      </Typography>
      <Typography className="green-input-title">
        * Place the order and enjoy 3 time meals delivered to your place.
      </Typography>
    </Grid>
  );
};
