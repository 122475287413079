import { Box, Container, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { Stack } from "@mui/material";
import React, { useEffect } from "react";
const ProductFeatures = (props) => {
	useEffect(() => {
		console.log(props);
	});

	return (
		<Container
			style={{
				backgroundColor: props.bg,
			}}
		>
			<Box style={styles.box}>
				<Stack gap={2} style={styles.box}>
					<Box style={styles.container}>
						<img src={props.img} style={styles.img} />
					</Box>
					<Typography style={styles.title}>{props.title}</Typography>
					<Stack
						style={{
							alignItems: "flex-start",
							justifyContent: "flex-start",
							display: "flex",
							flexDirection: "column",
							minHeight: 150,
							paddingBottom: 10,
						}}
						gap={2}
					>
						{props.bullets?.map((point) => {
							return (
								<Stack
									gap={1}
									direction={"row"}
									style={{
										alignItems: "flex-start",
										justifyContent: "flex-start",
										display: "flex",
										flexDirection: "row",
									}}
								>
									<CheckCircle style={styles.check} />
									<Typography style={styles.point}> {point}</Typography>
								</Stack>
							);
						})}
					</Stack>
				</Stack>
			</Box>
		</Container>
	);
};
export default ProductFeatures;
const styles = {
	container: {
		width: "80px",
		height: "80px",
	},
	box: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "column",
		height: 450,
		width: "100%",
		paddingTop: 10,
	},
	img: {
		width: "100%",
		height: "100%",
		objectFit: "contain",
	},
	title: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		fontSize: 18,
		color: "#5ec57e",
	},
	point: {
		fontFamily: "Poppins",
		fontWeight: "400",
		fontSize: 12,
		color: "#347571",
	},
	check: {
		fontSize: 18,
		color: "#57B368",
	},
};
