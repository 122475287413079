import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import multiple_subscriptions from "../../../assets/images/features/multiple_subscriptions.webp";
import curated from "../../../assets/images/features/curate.jpg";
import vision from "../../../assets/images/features/plate-method-en.webp";
import scheduled from "../../../assets/images/features/scheduled_delivery.webp";
import switching from "../../../assets/images/features/switching.jpg";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";

const styles = {
  title: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    color: "#444444",
    textAlign: "start",
  },
  header: {
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: "700",
    color: "#444444",
    textAlign: "start",
  },
  content: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    color: "#444444",
    marginTop: 15,
    textAlign: "start",
  },
  strong: {
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: "700",
    color: "#3FA42B",
    marginTop: 15,
    textAlign: "start",
  },
};

const Features = (props) => {
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showIndicators
      swipable
      emulateTouch
      showStatus={false}
      showArrows={false}
    >
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={6}>
          <Stack>
            <Typography style={styles.title}>Our Mission</Typography>
            <Typography style={styles.header}>
              Help 1B people to lead Healthy food habits
            </Typography>
            <Typography style={styles.content}>
              We collaborate with reputed Kitchens, nutritionists and personal
              trainers to provide a balanced diet for all
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 1, width: 500 }}>
            <img
              src={vision}
              alt="Healthy Food"
              title="Healthy Food"
              width="100%"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={6}>
          <Stack>
            <Typography style={styles.title}>Curated Meal Plans</Typography>
            <Typography style={styles.header}>
              Choose from <span style={styles.strong}>200+ </span> Meal
              Providers.
            </Typography>
            <Typography style={styles.content}>
              Discover curated daily, weekly, and monthly meal plans from more
              than 100 trusted meal providers that fit your lifestyle and
              budget.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 1, width: 400 }}>
            <img
              src={curated}
              alt="Curated Meal Plans"
              title="Curated Meal Plans"
              width="100%"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={6}>
          <Stack>
            <Typography style={styles.title}>
              Multiple Subscriptions for All Meals
            </Typography>
            <Typography style={styles.header}>
              Do not compromise your preferences
            </Typography>
            <Typography style={styles.content}>
              With efoodia, choose separate subscriptions for all your meals,
              and enjoy affordable and customized options at your convenience.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 1, width: 400 }}>
            <img
              src={multiple_subscriptions}
              alt="Multiple Subscriptions for All Meals"
              title="Multiple Subscriptions for All Meals"
              width="100%"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={6}>
          <Stack>
            <Typography style={styles.title}>
              Meal Provider Switching
            </Typography>
            <Typography style={styles.header}>
              Switch between meal providers
            </Typography>
            <Typography style={styles.content}>
              Want to try new meal choices? With efoodia, easily switch between
              meal providers every week or month and broaden your culinary
              horizon, even during middle of the subscription for Free!
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 1, width: 400 }}>
            <img
              src={switching}
              alt=" Meal Provider Switching"
              title=" Meal Provider Switching"
              width="100%"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={6}>
          <Stack>
            <Typography style={styles.title}>
              Flexibility and Convenience
            </Typography>
            <Typography style={styles.header}>
              Your Preferred location, your preferred time.
            </Typography>
            <Typography style={styles.content}>
              Schedule deliveries to multiple locations, including your home or
              workplace! Pause, transfer, or cancel your subscription easily at
              any time with our user-friendly interface.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 1, width: 500 }}>
            <img
              src={scheduled}
              alt="Flexibility and Convenience"
              title="Flexibility and Convenience"
              width="100%"
            />
          </Box>
        </Grid>
      </Grid>
    </Carousel>
  );
};
export default Features;
