import {
	Box,
	Button,
	Container,
	Grid,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import Sales from "../entities/sales";
const Connect = (props) => {
	const [orgName, setOrgName] = useState("");
	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [remarks, setRemarks] = useState("");
	const [error, setError] = useState(false);
	const [sales, setSales] = useState(new Sales());
	const [submitted, setSubmitted] = useState("");
	const [sumissionError, setSubmissionError] = useState(false);
	const handleSubmit = () => {
		if (!orgName || !fullName || !phone || !email) {
			return setError(true);
		} else {
			setError(false);
		}
		const payload = {
			data: {
				name: fullName,
				regionId: "UAE",
				applicationId: "EFCorp",
				pincode: "123456",
				organisationName: orgName,
				domain: "unknown",
				phoneNumber: { countryCode: "+971", number: phone },
				email: email,
				source: "ef_website",
				notes: remarks,
			},
		};
		sales
			.create_lead(payload)
			.then((res) => {
				setSubmitted(
					"Your request is submitted. Our Account manager shall connect with you shortly."
				);
			})
			.catch((err) => {
				setSubmissionError(true);
			});
	};

	return (
		<Container style={{ marginTop: 20 }}>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={4}>
					<Stack style={{ width: "100%" }}>
						<Typography style={styles.hero_heading}>Connect with us</Typography>
						<Typography style={styles.title}>
							Get Corporate Discounts
						</Typography>
						<Box style={styles.hero_image_container}>
							<img
								src='https://ik.imagekit.io/efoodia/Web/mealbox_bg.svg?updatedAt=1711952343979'
								style={styles.hero_image}
							/>
						</Box>
					</Stack>
				</Grid>
				<Grid item xs={12} sm={2}></Grid>
				<Grid item xs={12} sm={5}>
					<Paper style={styles.container}>
						<Stack gap={3}>
							<Typography style={styles.title1}>
								Enter the following details & Get Corporate Discounts.
							</Typography>

							<TextField
								variant='filled'
								required
								value={fullName}
								error={!fullName && error}
								helperText={!fullName && error ? "This field is required" : ""}
								label='Full Name'
								size='small'
								onChange={(e) => setFullName(e.target.value)}
							/>
							<TextField
								variant='filled'
								label='Organization Name'
								size='small'
								onChange={(e) => setOrgName(e.target.value)}
								required
								value={orgName}
								error={!orgName && error}
								helperText={!orgName && error ? "This field is required" : ""}
							/>
							<TextField
								variant='filled'
								label='Email'
								size='small'
								onChange={(e) => setEmail(e.target.value)}
								required
								value={email}
								error={!email && error}
								helperText={!email && error ? "This field is required" : ""}
							/>
							<TextField
								variant='filled'
								label='Phone Number'
								size='small'
								type='number'
								placeholder='0544229277'
								onChange={(e) => setPhone(e.target.value)}
								required
								value={phone}
								error={!phone && error}
								helperText={!phone && error ? "This field is required" : ""}
							/>
							<TextField
								variant='filled'
								label='Notes'
								maxRows={4}
								multiline
								size='small'
								minRows={4}
								onChange={(e) => setRemarks(e.target.value)}
							/>
							{sumissionError && (
								<Typography
									style={{ color: "red", fontSize: 14, fontWeight: "500" }}
								>
									Error While Subitting your request
								</Typography>
							)}
							{submitted && (
								<Typography
									style={{ color: "green", fontSize: 14, fontWeight: "500" }}
								>
									{submitted}
								</Typography>
							)}
							<Button style={styles.hero_button} onClick={handleSubmit}>
								Get in Touch
							</Button>
						</Stack>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={1}></Grid>
			</Grid>
		</Container>
	);
};
export default Connect;
const styles = {
	hero_button: {
		height: 50,
		borderRadius: 5,
		width: "100%",
		backgroundColor: "#57B368",
		color: "#ffffff",
		fontFamily: "Poppins",
		fontWeight: "bold",
	},
	check: {
		fontSize: 18,
		color: "#57B368",
	},
	vertical_line: {
		width: "50%",
		height: "100%",
		backgroundColor: "#57B368",
	},
	container: {
		padding: 20,
		boxShadow:
			"rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
	},
	line: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	grid: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	paper_highlighted: {
		width: "100%",

		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		cursor: "pointer",
	},
	paper_normal: {
		width: "100%",
		paddingRight: 1,
		backgroundColor: "transparent",
		alignItems: "flex-start",
		justifyContent: "center",
		display: "flex",
		cursor: "pointer",
	},
	title: {
		fontFamily: "Poppins",
		fontWeight: "600",
		fontSize: "16px",
		color: "#444444",
		paddingTop: 10,
	},
	title1: {
		fontFamily: "Poppins",
		fontWeight: "600",
		fontSize: "26px",
		color: "#444444",
		paddingTop: 10,
		textAlign: "center",
	},
	desc: {
		fontFamily: "Poppins",
		fontWeight: "500",
		fontSize: "14px",
		color: "#000000",
		paddingBottom: 5,
	},
	root: {
		width: "100%",
		paddingRight: 10,
		minHeight: "80vh",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	hero_image_container: {
		width: "100%",
		height: "100%",
	},
	hero_image: {
		width: "100%",
		height: "100%",
		objectFit: "contain",
	},
	hero_heading: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		fontColor: "#444444",
		fontSize: 40,
	},
};
