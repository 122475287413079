import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import user from "./userReducer";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

import thunk from "redux-thunk";

const reducers = combineReducers({
  user,
});
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
