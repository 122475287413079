import { ThemeProvider } from "@emotion/react";
import theme from "../common/theme";
import {
	Avatar,
	Box,
	Button,
	CircularProgress,
	Collapse,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	styled,
	Switch,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import catalog from "../api/catalog";
import _ from "lodash";
import {
	ArrowDownwardOutlined,
	ArrowDropDown,
	ArrowDropDownOutlined,
	KeyboardArrowDown,
	RadioButtonCheckedOutlined,
} from "@material-ui/icons";
import "./Store.css";
import { Circle } from "@mui/icons-material";

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 15,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(9px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(12px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: "green",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor:
			theme.palette.mode === "dark"
				? "rgba(255,255,255,.35)"
				: "rgba(0,0,0,.25)",
		boxSizing: "border-box",
	},
}));

const Store = ({ props }) => {
	let { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [categoryProducts, setCategoryProducts] = useState([]);
	const [collapsedCategs, setCollapsedCategs] = useState([]);
	const [products, setProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [vegOnly, setVegOnly] = useState(false);
	const [store, setStore] = useState({});
	const [originalProducts, setOriginalProducts] = useState([]);
	useEffect(() => {
		console.log(id);
		const payload = {
			data: {
				storeIds: id,
			},
		};
		catalog
			.getStore(id)
			.then((res) => {
				setStore(res.data);
			})
			.catch((err) => console.log(err));
		setLoading(true);
		catalog
			.getStoreProducts(id)
			.then((results) => {
				const tempProducts = [];
				_.map(results, (o) => {
					if (o.product.productType == "base_product") {
						if (o.product.properties.length) {
							_.map(o.product.properties, (prop) => {
								if (prop.name === "Orientation") {
									if (prop.value.values) {
										if (
											prop.value.values.includes("Veg") &&
											prop.value.values.includes("Non Veg")
										) {
											tempProducts.push({ ...o, orientation: "Nonveg" });
										} else if (
											prop.value.values.length === 1 &&
											prop.value.values.includes("Veg")
										) {
											tempProducts.push({ ...o, orientation: "Veg" });
										} else if (
											prop.value.values.length === 1 &&
											prop.value.values.includes("Non Veg")
										) {
											tempProducts.push({ ...o, orientation: "Non Veg" });
										}
									} else {
										if (prop.value.value === "Veg") {
											tempProducts.push({ ...o, orientation: "Veg" });
										} else {
											tempProducts.push({ ...o, orientation: "Non Veg" });
										}
									}
								}
							});
							setLoading(false);
						} else {
							tempProducts.push({ ...o, orientation: "None" });
							setLoading(false);
						}
					}
				});
				return tempProducts;
			})
			.then((results) => {
				let categoryProds = {};
				_.map(results, (p) => {
					if (p.product.categoryIds[0] in categoryProds) {
						categoryProds[p.product.categoryIds[0]] = [
							...categoryProds[p.product.categoryIds[0]],
							p,
						];
					} else {
						categoryProds[p.product.categoryIds[0]] = [p];
					}
				});
				setOriginalProducts(categoryProds);
				setCategoryProducts(categoryProds);
				const exp = _.map(Object.keys(categoryProds), (c) => {
					return { category: c, collapsed: true };
				});

				setCollapsedCategs(exp);
				setProducts(categoryProds);
				let categories = [];
				_.map(results, (prod) => {
					if (prod.product.categoryIds.length) {
						for (const i in prod.product.categoryIds) {
							if (
								!categories.includes({
									name: prod.product.categoryIds[i],
								})
							) {
								categories.push({
									name: prod.product.categoryIds[i],
								});
							}
						}
					}
				});
				setCategories(_.uniqBy(categories, "name"));
				setLoading(false);
			});
	}, []);

	const getPrice = (item) => {
		let price = "";
		const storeProd = item.storeProduct;
		if (storeProd) {
			if (storeProd.priceMapping) {
				if (storeProd.priceMapping.value) {
					price = `\u20B9` + storeProd.priceMapping.value.pretaxAmount.amount;
				} else {
					const vprices = [];
					const variants = Object.keys(storeProd.priceMapping);
					const variantMapping = {};
					_.map(variants, (vid) => {
						const variant = _.filter(
							item.product.variants,
							(prodVariant) => prodVariant.uniqueProductId == vid
						);
						if (variant.length) {
							const prop = _.filter(
								variant[0].properties,
								(prop) => prop.name == "Portion"
							);
							if (prop.length) variantMapping[vid] = prop[0].value;
							else
								price = `\u20B9` + item.product.price.value.pretaxAmount.amount;
						} else
							price = `\u20B9` + item.product.price.value.pretaxAmount.amount;
					});
					_.map(Object.keys(variantMapping), (map) => {
						if (storeProd.priceMapping[map])
							vprices.push(
								`${variantMapping[map]} ${
									`\u20B9` +
									Number(storeProd.priceMapping[map].pretaxAmount.amount)
								}`
							);
						else
							price = `\u20B9` + item.product.price.value.pretaxAmount.amount;
					});
					price = vprices.join("  |  ");
				}
			} else price = `\u20B9` + item.product.price.value.pretaxAmount.amount;
		} else price = `\u20B9` + item.product.price.value.pretaxAmount.amount;
		return price;
	};

	const handleChange = (event) => {
		setVegOnly(event.target.checked);
		console.log(categoryProducts);
		let newList = categoryProducts;
		if (event.target.checked) {
			const vegProds = {};
			const categoryP = _.map(Object.keys(newList), (category) => {
				console.log(category);
				const veg = _.filter(
					newList[category],
					(prod) => prod.orientation == "Veg"
				);
				// vegProds.push(...veg)

				if (veg.length) {
					vegProds[category] = veg;
					return veg;
				} else return [];
			});
			setCategoryProducts(vegProds);
		} else setCategoryProducts(originalProducts);
	};
	return (
		<ThemeProvider theme={theme}>
			<div className='mainContainer'>
				{!_.isEmpty(store) ? (
					<Box className='storeBox'>
						{store.storeImages.length ? (
							<img className='storeImage' src={store.storeImages[0].path} />
						) : (
							<img
								className='storeImage'
								src={require("../assets/images/logo512.png")}
							/>
						)}
						<Typography className='storeName'>{store.name} Menu</Typography>
					</Box>
				) : (
					<></>
				)}
				<br />
				<br />
				<FormControl
					component='fieldset'
					variant='standard'
					style={{ marginLeft: "2em", marginBottom: "1em" }}
				>
					<FormGroup>
						<FormControlLabel
							control={
								<AntSwitch
									checked={vegOnly}
									onChange={handleChange}
									name='veg'
								/>
							}
							label='Veg Only'
						/>
					</FormGroup>
				</FormControl>
				{!_.isEmpty(products) && !loading ? (
					// <div
					//   style={{
					//     backgroundColor: '#F1F2F6',
					//     paddingBottom: 15,
					//     marginBottom: 70
					//   }}>
					collapsedCategs.length && !_.isEmpty(categoryProducts) && !loading ? (
						<Grid
							container
							xs={12}
							style={{
								backgroundColor: "#F1F2F6",
							}}
						>
							{_.map(Object.keys(categoryProducts), (cp) => {
								return (
									<Grid
										item
										xs={12}
										l={3}
										md={4}
										style={{
											justifyContent: "center",
											backgroundColor: "white",
											marginBottom: "1em",
										}}
										onClick={() => {
											const newexpanded = _.map(collapsedCategs, (c) => {
												if (c.category == cp) {
													c.collapsed = !c.collapsed;
													return c;
												} else return c;
											});
											setCollapsedCategs(newexpanded);
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												padding: 20,
											}}
										>
											<Typography className='categoryTitle'>{cp}</Typography>
											<KeyboardArrowDown />
										</div>
										<Collapse
											in={
												_.filter(collapsedCategs, (c) => c.category == cp)[0]
													.collapsed
											}
										>
											{_.map(categoryProducts[cp], (c) => {
												return (
													<ListItem
														sx={{
															marginBottom: "2em",
															paddingLeft: "2em",
															paddingRight: "2em",
														}}
														secondaryAction={
															<Box
																style={{
																	alignItems: "center",
																	display: "flex",
																	flexDirection: "column",
																}}
															>
																<ListItemAvatar>
																	<img
																		src={c.product.images.value[0].path}
																		className='image'
																	/>
																</ListItemAvatar>
																<Button
																	onClick={(e) => {
																		e.stopPropagation();
																		console.log("h");
																	}}
																	style={{
																		backgroundColor: "black",
																		color: "white",
																		height: "2em",
																		width: "1.5em",
																		fontSize: "0.7em",
																	}}
																>
																	ADD
																</Button>
															</Box>
														}
													>
														<ListItemAvatar
															sx={{ minWidth: 0, marginRight: "1em" }}
														>
															{c.orientation == "Veg" ? (
																<RadioButtonCheckedOutlined
																	style={{ fontSize: "1em", color: "green" }}
																/>
															) : (
																<RadioButtonCheckedOutlined
																	style={{ fontSize: "1em", color: "brown" }}
																/>
															)}
														</ListItemAvatar>

														<ListItemText
															primary={c.product.name}
															primaryTypographyProps={{
																fontSize: "0.9em",
																fontWeight: "400",
																fontFamily: "Nunito",
																letterSpacing: 0,
															}}
															secondary={
																<>
																	<Typography className='price'>
																		{getPrice(c)}
																	</Typography>
																	<Typography className='description'>
																		{c.product.description.value
																			? c.product.description.value
																			: null}
																	</Typography>
																</>
															}
														/>
													</ListItem>
												);
											})}
											{/* <FlatList
                          data={categoryProducts[cp]}
                          keyExtractor={keyExtractor}
                          renderItem={render2}
                          // onEndReached={props.fetchMore}
                          onEndReachedThreshold={0.5}
                          showsVerticalScrollIndicator={false}
                          extraData={products}
                        /> */}
										</Collapse>
									</Grid>
								);
							})}
						</Grid>
					) : (!collapsedCategs.length || !_.isEmpty(categoryProducts)) &&
					  !loading ? (
						<></>
					) : (
						// <FlatList
						//   data={storeProducts}
						//   renderItem={item => <RenderProducts item={item.item} />}
						// />
						<></>
					)
				) : // </div>

				_.isEmpty(products) && !loading ? (
					<div>
						<Typography style={{ alignContent: "center", alignSelf: "center" }}>
							Menu Yet to be Added
						</Typography>
					</div>
				) : (
					<CircularProgress />
				)}
			</div>
		</ThemeProvider>
	);
};

export default Store;
