import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Web from "../../../assets/images/web.png";
import { Check } from "@material-ui/icons";
import Play from "../../../assets/images/google_play.png";
import Appstore from "../../../assets/images/appstore.png";

const CTAApp = (props) => {
  return (
    <Grid
      container
      spacing={2}
      direction={{ xs: "column", md: "row" }}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        minHeight: "70vh",
      }}
    >
      <Grid item xs={12} md={6}>
        <Stack>
          <img src={Web} alt="app-image" title="app-image" width={"100%"} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ cursor: "pointer" }}
              src={Play}
              width={"24%"}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.encyclofoodia.shop",
                  "_blank"
                )
              }
            />
            <img
              style={{ cursor: "pointer" }}
              src={Appstore}
              width={"20%"}
              onClick={() =>
                window.open(
                  "https://apps.apple.com/in/app/encyclofoodia/id6456944295",
                  "_blank"
                )
              }
            />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontSize: 36,
              color: "#444444",
              marginBottom: "10px",
            }}
          >
            Customize Your Meals with
            <span style={{ fontWeight: "600" }}> Trustworthy </span>
            Meal Providers
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: 18,
              color: "#444444",
            }}
          >
            Switch between Meal Providers any time. 200+ meal providers to
            choose from.
          </Typography>
          <Typography
            style={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: 14,
              color: "#3fa42b",
              marginTop: 10,
            }}
          >
            Our Meal Plans Are{" "}
          </Typography>
          <Grid container>
            <Grid item md={6}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 14,
                  color: "#444444",
                }}
              >
                <Check
                  style={{ fontSize: "11px", color: "#3fa42b", marginRight: 5 }}
                />{" "}
                350 - 500 calories
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 14,
                  color: "#444444",
                }}
              >
                <Check
                  style={{ fontSize: "11px", color: "#3fa42b", marginRight: 5 }}
                />
                Essential Fats
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 14,
                  color: "#444444",
                }}
              >
                <Check
                  style={{ fontSize: "11px", color: "#3fa42b", marginRight: 5 }}
                />{" "}
                Complex Carbohydrates
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 14,
                  color: "#444444",
                }}
              >
                <Check
                  style={{ fontSize: "11px", color: "#3fa42b", marginRight: 5 }}
                />
                Dietary Fiber
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 14,
                  color: "#444444",
                }}
              >
                <Check
                  style={{ fontSize: "11px", color: "#3fa42b", marginRight: 5 }}
                />{" "}
                Lean Protien
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 14,
                  color: "#444444",
                }}
              >
                <Check
                  style={{ fontSize: "11px", color: "#3fa42b", marginRight: 5 }}
                />
                Low Sodium
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default CTAApp;
