import { Box, Container, Grid, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import React from "react";

const HeroResearch = (props) => {
	return (
		<Box style={styles.root}>
			<Container>
				<Grid container spacing={2} direction='row' style={styles.root}>
					<Grid item xs={12} sm={4}>
						<Stack gap={2}>
							<Typography style={styles.hero_heading}>HERO Research</Typography>
							<Typography style={styles.hero_subtitle}>
								Research from the Health Enhancement Research Organization
								(HERO) shows that Food has direct link to employee performance.
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={2} sm={2}></Grid>
					<Grid item xs={12} sm={6}>
						<Typography style={styles.quote}>
							"Employees who has right eating habits were <strong>25%</strong>{" "}
							more likely to have higher job performance and lower absenteeism"
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};
export default HeroResearch;
const styles = {
	root: {
		width: "100%",
		paddingRight: 10,
		minHeight: "30vh",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		flexDirection: "row",
	},
	hero_heading: {
		fontFamily: "Poppins",
		fontWeight: "bold",
		fontColor: "#444444",
		fontSize: 40,
	},
	hero_subtitle: {
		fontFamily: "Poppins",
		fontWeight: "500",
		fontColor: "#444444",
		fontSize: 18,
	},
	hero_image_container: {
		maxWidth: "600px",
		maxHeight: "600px",
	},
	hero_image: {
		width: "100%",
		height: "100%",
		objectFit: "contain",
	},
	quote: {
		fontFamily: "Poppins",
		fontWeight: "500",
		color: "#57b368",
		fontSize: 34,
		fontStyle: "italic",
	},
};
